import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { getMinPrice } from '../../features/api/getMinPrice';


export default function AddMinPrice({ valueMinPrice }) {
  const [minPrice, setMinPrice] = React.useState("");
  const dispatch = useDispatch();

  React.useEffect(() => {
    setMinPrice(valueMinPrice);
    dispatch(getMinPrice(valueMinPrice))
  }, [valueMinPrice])

  return (
    <div className="add__min__price">
      <Box
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch', bgcolor: "white" },
        }}
        noValidate
        autoComplete="off"
      >
        {/* Минимальная стоимость */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            id="outlined-basic"
            value={minPrice}
            onChange={(e) => {
              setMinPrice(e.target.value);
              dispatch(getMinPrice(e.target.value))
            }}
            label="Минимальная стоимость"
            variant="outlined"
          />
        </div>
      </Box>
    </div>
  );
}
