import { createSlice } from '@reduxjs/toolkit';

export const getConcertProgrammSlice = createSlice({
  name: 'concertProgramm',
  initialState: {
    value: "",
  },
  reducers: {
    getConcertProgramm: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const {getConcertProgramm} = getConcertProgrammSlice.actions;
export default getConcertProgrammSlice.reducer;