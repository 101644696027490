import { createSlice } from '@reduxjs/toolkit';

export const getTypeEventSlice = createSlice({
  name: 'typeEventApi',
  initialState: {
    value: [],
    idEvent: "",
  },
  reducers: {
    getTypeEvent: (state, action) => {
      state.value = action.payload;
    },
    idTypeEvent: (state, action) => {
      state.idEvent = action.payload;
    }
  },
});

export const {getTypeEvent} = getTypeEventSlice.actions;
export const {idTypeEvent} = getTypeEventSlice.actions;

export default getTypeEventSlice.reducer;