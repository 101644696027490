import { createSlice } from '@reduxjs/toolkit';

export const getCityIdSlice = createSlice({
  name: 'cityId',
  initialState: {
    value: '',
  },
  reducers: {
    getCityId: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const {getCityId} = getCityIdSlice.actions;
export default getCityIdSlice.reducer;