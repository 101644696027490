import { configureStore } from '@reduxjs/toolkit';

import citySlice from '../features/city/cityChoicer';
import ticketSlice from '../features/ticket/ticketSlice';
import getCitySlice from '../features/api/cityApi';
import getExecutorSlice from '../features/api/executorApi';
import getOrganizerSlice from '../features/api/organizerApi';
import getLocationSlice from '../features/api/locationApi';
import getCityIdSlice from '../features/api/getCityId';
import getTypeEventSlice from '../features/api/typeEventApi';
import getAgeSlice from '../features/api/ageApi';
import getNameSlice from '../features/api/getName';
import getMinPriceSlice from '../features/api/getMinPrice';
import getMaxPriceSlice from '../features/api/getMaxPrice';
import getDescriptionSlice from '../features/api/getDescription';
import getConcertProgrammSlice from '../features/api/getConcertProgramm';
import getDateSlice from '../features/api/getDateTime';
import getTime from '../features/api/getTime';
import photoSlice from '../features/savePathFile/photo'
import seoLinksSlice from '../features/api/SeoLinks';
import eventSlice from '../features/eventCard/eventCard';
import colorSlice from '../features/api/colorBtn';

export const store = configureStore({
  reducer: {
    ticket: ticketSlice,
    city: citySlice,
    checkEvent: photoSlice,
    cityApi: getCitySlice,
    executorApi: getExecutorSlice,
    organizerApi: getOrganizerSlice,
    locationApi: getLocationSlice,
    getCityid: getCityIdSlice,
    getTypeEvent: getTypeEventSlice,
    getAge: getAgeSlice,
    getName: getNameSlice,
    getMinPrice: getMinPriceSlice,
    getMaxPrice: getMaxPriceSlice,
    getDescription: getDescriptionSlice,
    getConcertProgramm: getConcertProgrammSlice,
    getDate: getDateSlice,
    getTime: getTime,
    seoLinks: seoLinksSlice,
    eventCard: eventSlice,
    colorButton: colorSlice,
  },
});
