import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import axios from 'axios';

import EventCard from '../components/EventCard/EventCard';

import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';

import backgroundFire from '../assets/Disco/backgroundFire.jpg';
import FireLogo from '../assets/Disco/logo111.png';

import backgroundUnlim from '../assets/Unlimited/bg.png';
import UnlimLogo from '../assets/Unlimited/logo.png';

import backgroundOrch from '../assets/Orchestral/bgcard.jpg';
import OrchLogo from '../assets/Orchestral/logo.png';

import bgDisco from '../';

import { useSelector, useDispatch } from "react-redux";
import { incrementByAmount } from "../features/city/cityChoicer";

// компонент домашней страницы
const HomePageOskol = () => {
  const dispatch = useDispatch();
  dispatch(incrementByAmount('Старый Оскол'));
  // const [data, setData] = useState([]); // Создаем переменную состояния "data" и функцию для ее обновления "setData"

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch('http://localhost:1337/api/events?populate=*'); // Отправляем GET-запрос на сервер для получения данных
  //       const jsonData = await response.json(); // Преобразуем ответ в формат JSON
  //       setData(jsonData.data); // Обновляем состояние "data" с помощью полученных данных
  //     } catch (error) {
  //       console.error('Ошибка при получении данных:', error); // В случае ошибки выводим сообщение об ошибке в консоль
  //     }
  //   };

  //   fetchData(); // Вызываем функцию fetchData при каждом рендере компонента
  // }, []);

  // console.log(data); // Выводим данные в консоль
  var _tmr = window._tmr || (window._tmr = []); _tmr.push({ type: 'reachGoal', id: 3467367, goal: 'view_main_page'});
  return (
    <>
      <Helmet>
        <title>Купить билеты на концерты в Старый Оскол</title>
        <meta
          name="description"
          content="Купить билеты на концерты Старый Оскол! Удобные схемы залов, электронные билеты.
          Легко найти интересующий вас концерт, выбрать место в зале и оформить заказ всего в несколько кликов.
          Наша платформа обеспечивает удобный и безопасный способ покупки билетов онлайн. Электронные билеты cразу после оплаты."
        />
      </Helmet>
      <div className="page">
        <Header /> {/* Компонент Header */}
        <div className="centering">
          <div className="centering__cards">
            {/* {data.map((event) => (
              <EventCard
                key={event.id}
                id={event.id}
                price={event.attributes.Price}
                data={event.attributes.Date + ' ' + event.attributes.StartTime}
                location={
                  event.attributes.location.data.attributes.hallName +
                  ' ' +
                  event.attributes.location.data.attributes.Location
                }
                buttonlink={event.attributes.BuyLink}
                routerlink="/FireBird"
                eventimage={`http://localhost:1337${event.attributes.CardBackground.data.attributes.url}`}
                eventlogo={`http://localhost:1337${event.attributes.Logo.data.attributes.url}`}
              />
            ))} */}
            {/*
            <EventCard
              price={'1500-2300 ₽'}
              data={'16 АПРЕЛЯ 19 : 00 ВТОРНИК'}
              location={'"ЦМИ" ул. Ленина, 20'}
              buttonlink={'https://xn--90acilpt1a.xn--p1acf/wp/tc-events/simfo-show-unlimited/'}
              routerlink="/Unlimited"
              eventimage={backgroundUnlim}
              eventlogo={UnlimLogo}
          /> */}
            <EventCard
              price={'1500-2300 ₽'}
              data={'19 ИЮНЯ 19 : 00 СРЕДА'}
              location={'"ЦМИ" ул. Ленина, 20'}
              buttonlink={'https://билетов.рус/wp/tc-events/simfo-show-disco-80x-19-06/'}
              routerlink="/Disco80"
              eventimage={backgroundFire}
              eventlogo={FireLogo}
            />
            {/* <EventCard
              price={'500-1200 ₽'}
              data={'20 МАРТА 19 : 00 СРЕДА'}
              location={'КЗ «СВИРИДОВСКИЙ» ул. Сонина, 4'}
              buttonlink={'https://xn--90acilpt1a.xn--p1acf/wp/tc-events/orchestral-masterpieces/'}
              routerlink="/Orchestral"
              eventimage={backgroundOrch}
              eventlogo={OrchLogo}
            /> */}
          </div>
        </div>
      </div>
      {/* Футер сайта */}
      <Footer />
    </>
  );
};

export default HomePageOskol;
