import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import axios from 'axios';
import EventCard from '../components/EventCard/EventCard';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import { useDispatch } from "react-redux";
import { incrementByAmount } from "../features/city/cityChoicer";
import { eventCardMain } from '../features/eventCard/eventCard';

export default function OtherHomePage() {
  const { city } = useParams()
  const [cards, setCards] = useState([]);
  const [isCity, setIsCity] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlApi = process.env.REACT_APP_API_URL;
  
  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${urlApi}/api/getEvent/city/${city}`);
      dispatch(eventCardMain(response.data))
      setCards(response.data)
      setIsCity(response.data)
      dispatch(incrementByAmount(response.data[0].location.city.name));
    } catch (e) {
      console.log(e)
    };
  };

  const fetchCity = async () => {
    try {
      const response = await axios.get(`${urlApi}/api/getCity`);
      for (const data of response.data) {
        if(data.translit == city){
          return
        }
      }
      return navigate("/404")
    } catch (e) {
      console.log(e)
    };
  };


  const optionsDate = {
    month: 'long',
    day: 'numeric',
  };

  const optionsTime = {
    hour: 'numeric',
    minute: 'numeric',
  };

  const optionsWeekday = {
    weekday: 'long',
  };

  useEffect(() => {
    fetchCity();
    fetchEvents();
  }, [city]);



  return (
    <>
      <Helmet>
        <title>Купить билеты на концерты в Курске</title>
        <meta
          name="description"
          content="Купить билеты на концерты Курск! Удобные схемы залов, электронные билеты.
        Легко найти интересующий вас концерт, выбрать место в зале и оформить заказ всего в несколько кликов.
        Наша платформа обеспечивает удобный и безопасный способ покупки билетов онлайн. Электронные билеты cразу после оплаты."
        />
      </Helmet>
      <div className="page">
        <Header />
        <div className="centering">
          <div className="centering__cards">
            {cards.map((post) => (
              <EventCard
                key={post._id}
                price={`${post.minPrice}-${post.maxPrice} ₽`}
                data={`${new Date(post.dateTime).toLocaleString("ru", optionsDate).toUpperCase()}
                ${new Date(post.dateTime).toLocaleString("ru", optionsTime)}
                ${new Date(post.dateTime).toLocaleString("ru", optionsWeekday).toUpperCase()}`}
                location={`${post.location.place} ${post.location.street} ${post.location.house}`}
                buttonlink={`${post.urlEvent}`}
                routerlink={`/${post.location.city.translit}/${post.urlLending}`}
                eventimage={post.previewPhoto}
                eventlogo={post.logo}
              />
            ))
            }
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

