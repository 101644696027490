import { createSlice } from '@reduxjs/toolkit';

export const getLocationSlice = createSlice({
  name: 'locationApi',
  initialState: {
    value: [],
    idLocation: "",
  },
  reducers: {
    getAllLocation: (state, action) => {
      state.value = action.payload;
    },
    idLocation: (state, action) => {
      state.idLocation = action.payload;
    },

  },
});

export const {getAllLocation} = getLocationSlice.actions;
export const {idLocation} = getLocationSlice.actions;
export default getLocationSlice.reducer;