import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import { Menu, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getAllExecutor } from '../../../features/api/executorApi';
import { idAllExecutor } from '../../../features/api/executorApi';
import axios from 'axios';

export default function UpdateExecutor({ updateExecutor, updateExecutorId }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tempId, setTempId] = React.useState('');
  const [isExecutor, setExecutor] = React.useState('');
  const allExecutors = useSelector((state) => state.executorApi.value);
  const dispatch = useDispatch();
  const urlApi = process.env.REACT_APP_API_URL;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Get
  const fetchExecutors = async () => {
    try {
      const response = await axios.get(`${urlApi}/api/getArtist/`);
      dispatch(getAllExecutor(response.data));
    } catch (error) {
      console.log("Ошибка получения данных: ", error);
    };
  };

  const getSelectedExecutorName = () => {
    const selectedExecutor = allExecutors.find((executor) => executor._id === tempId);
    return selectedExecutor ? selectedExecutor.name : '';
  };

  useEffect(() => {
    setExecutor(updateExecutor);
    dispatch(idAllExecutor(updateExecutorId))
  }, [updateExecutor, updateExecutorId])

  return (
    <div className="add__executor">
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch', bgcolor: "white" },
        }}
        noValidate
        autoComplete="off"
      >
        {/* Исполнители */}
        <div style={{ display: "flex", alignItems: "center" }}>

          <TextField
            disabled
            id="outlined-select-currency-native"
            value={tempId ? getSelectedExecutorName() : isExecutor}
            label="Исполнитель"        
          >
            {allExecutors.map((executor) => (
              <MenuItem key={executor._id} value={executor._id}>
                {executor.name}
              </MenuItem>
            ))}
          </TextField>

          {/* Button */}
          <Button variant="outlined" onClick={handleClick}>
            <EditIcon />
          </Button>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <TextField
              id="outlined-select-currency-native"
              select
              value={tempId || ''}
              label="Исполнитель"
              onChange={(e) => {
                setTempId(e.target.value);
                dispatch(idAllExecutor(e.target.value))
              }}
              onMouseDown={() => fetchExecutors()}
              sx={{ width: 300 }}
            >
              {allExecutors.map((executor) => (
                <MenuItem key={executor._id} value={executor._id} onClick={handleClose}>
                  {executor.name}
                </MenuItem>
              ))}
            </TextField>
          </Menu>
        </div>
      </Box>
    </div>
  )
}