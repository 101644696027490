import { createSlice } from '@reduxjs/toolkit';

export const getAgeSlice = createSlice({
  name: 'ageApi',
  initialState: {
    value: [],
    idAge: "",
  },
  reducers: {
    getAllAge: (state, action) => {
      state.value = action.payload;
    },
    idAllAge: (state, action) => {
      state.idAge = action.payload;
    }
  },
});

export const {getAllAge} = getAgeSlice.actions;
export const {idAllAge} = getAgeSlice.actions;
export default getAgeSlice.reducer;