import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { getLink } from '../../features/api/getMinPrice';

export default function AddLinkForm({ valueLinkTable }) {
  const [link, setLink] = React.useState("");
  const dispatch = useDispatch();

  React.useEffect(() => {
    setLink(valueLinkTable)
    dispatch(getLink(valueLinkTable))
  }, [valueLinkTable])

  return (
    <div className="add__link">
      <Box
        sx={{ '& .MuiTextField-root': { m: 1, width: '25ch', bgcolor: "white" }, }}
        noValidate
        autoComplete="off"
      >
        {/* Ссылка на таблицу*/}
        <div style={{ display: "flex", alignItems: "center" }}>
          <label>
            <TextField
              id="outlined-basic"
              value={link}
              onChange={(e) => {
                setLink(e.target.value);
                dispatch(getLink(e.target.value))
              }}
              label="Ссылка на таблицу"
              variant="outlined"
            />
          </label>
        </div>
      </Box>
    </div>
  )
}
