import * as React from 'react';
import Box from '@mui/material/Box';
import AddExecutor from '../components/AddEvent/AddExecutor';
import AddOrganizator from '../components/AddEvent/AddOrganizer';
import AddCity from '../components/AddEvent/AddCity';
import AddLocationCity from '../components/AddEvent/AddLocation';
import FileUpload from '../components/upload/fileupload';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { pathPhotoChange, pathBannerChange, pathLogoChange, pathPreviewChange, pathVideoBodyChange, pathVideoHeadChange } from '../features/savePathFile/photo';
import AddTypeEvent from '../components/AddEvent/AddTypeEvent';
import AddName from '../components/AddEvent/AddName';
import AddAge from '../components/AddEvent/AddAge';
import AddDateTime from '../components/AddEvent/AddDateTime';
import AddMinPrice from '../components/AddEvent/AddMinPrice';
import AddMaxPrice from '../components/AddEvent/AddMaxPrice';
import AddDescription from '../components/AddEvent/AddDescription';
import AddConcertProgramm from '../components/AddEvent/AddConcertProgramm';
import Typography from '@mui/material/Typography';
import AddLinkForm from '../components/AddEvent/AddLinkForm';
// import AddPrew from '../components/AddEvent/AddPrew';
import "../scss/Admin.css"
import axios from 'axios';
import AddUrlLink from '../components/AddEvent/AddUrl';
import AddTitleSeo from '../components/AddEvent/AddTitleSeo';
import AddDescriptionSeo from '../components/AddEvent/AddDescriptionSeo';
import AddColorButton from '../components/AddEvent/AddColorButton';
import AddLinkVideo from '../components/AddEvent/AddLinkVideo';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import inMemoryJWT from '../service/inMemoryJWT';

function AdminAdd() {
  const [value, setValue] = React.useState('one');
  const [isSend, setIsSend] = React.useState(false);

  const ExecutorId = useSelector((state) => state.executorApi.idExecutor);
  const OrganizatorId = useSelector((state) => state.organizerApi.idOrganizer);
  const LocationId = useSelector((state) => state.locationApi.idLocation);
  const EventId = useSelector((state) => state.getTypeEvent.idEvent);
  const AgeId = useSelector((state) => state.getAge.idAge);
  const date = useSelector((state) => state.getDate.value);
  const time = useSelector((state) => state.getTime.value);
  const finalTime = useSelector((state) => state.getTime.finalTime);
  const priceMin = useSelector((state) => state.getMinPrice.value);
  const priceMax = useSelector((state) => state.getMaxPrice.value);
  const name = useSelector((state) => state.getName.value);
  const description = useSelector((state) => state.getDescription.value);
  const concertProgramm = useSelector((state) => state.getConcertProgramm.value); //<--------
  const gallaryPhoto = useSelector((state) => state.checkEvent.pathGallary);
  const prewPhoto = useSelector((state) => state.checkEvent.pathPreview);
  const logo = useSelector((state) => state.checkEvent.pathLogo);
  const banner = useSelector((state) => state.checkEvent.pathBanner); //<-------- || videoHead
  const urlEvent = useSelector((state) => state.getMinPrice.link);

  const videoHead = useSelector((state) => state.getMinPrice.videoHead); //<---------- || banner
  const videoBody = useSelector((state) => state.getMinPrice.videoBody);

  const urlPage = useSelector((state) => state.getMinPrice.urlLink);
  const titeLink = useSelector((state) => state.seoLinks.seo);
  const colorBkg = useSelector((state) => state.colorButton.colorBkg);
  const colorColor = useSelector((state) => state.colorButton.colorColor);

  const urlApi = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();

  const handleSubmint = async () => {
    const formData = new FormData();
    formData.append('artist', ExecutorId);
    formData.append('organizer', OrganizatorId);
    formData.append('location', LocationId);
    formData.append('rating', AgeId);
    formData.append('typeEvent', EventId);
    formData.append('name', name);
    formData.append('date', date);
    formData.append('time', time);
    formData.append('duration', finalTime);
    formData.append('minPrice', priceMin);
    formData.append('maxPrice', priceMax);
    formData.append('description', description);
    formData.append('concertProgramm', concertProgramm);
    formData.append('gallaryPhoto', JSON.stringify(gallaryPhoto));
    formData.append('prewPhoto', prewPhoto);
    formData.append('logo', logo);
    formData.append('banner', banner);
    formData.append('urlEvent', urlEvent);
    formData.append('urlVideoHead', videoHead);
    formData.append('urlVideoBody', videoBody);
    formData.append('urlLending', urlPage);
    formData.append('seo', JSON.stringify(titeLink));
    formData.append('backgroundColorButton', colorBkg);
    formData.append('colorButton', colorColor);


    const ResourceClient = axios.create({
      baseURL: `${urlApi}`,
    });

    ResourceClient.interceptors.request.use(
      (config) => {
        const accessToken = inMemoryJWT.getToken();
        if (accessToken) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        };
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
    ResourceClient.post(`/api/setEvent/`, formData).then(data => {
      if (data) {
        setIsSend(true)
      }
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateLogo = (myPath) => {
    dispatch(pathLogoChange(myPath));
  };

  const updateBanner = (myPath) => {
    dispatch(pathBannerChange(myPath));
  };

  const updateVideoHead = (myPath) => {
    dispatch(pathVideoHeadChange(myPath));
  };

  const updatePreview = (myPath) => {
    dispatch(pathPreviewChange(myPath));
  };

  const updateVideoBody = (myPath) => {
    dispatch(pathVideoBodyChange(myPath));
  };

  const updateGalary = (myPath) => {
    dispatch(pathPhotoChange(myPath));
  };

  return (
    <>
      <Box sx={{ width: '100%', marginTop: 1, marginBottom: 1, marginLeft: 1.3 }}>
        <Stack spacing={2} direction="row">
          <Button variant="contained" component={Link} to="/Admin">Добавить событие</Button>
          <Button variant="outlined" component={Link} to="/Update">Редактировать события</Button>
        </Stack>
      </Box>

      <div className="admin__panels" onClick={() => { setIsSend(false) }}>
        <div className="add__other__event" style={{ backgroundColor: "#ffffff", borderRadius: 10, paddingRight: 10, marginTop: 10 }}>
          <AddExecutor executorId={""} />
          <AddOrganizator organizerId={""} />
          <AddCity locationId={""} />
          <AddLocationCity />
          <AddTypeEvent />
          <AddAge />
          <AddDateTime dateDate={""} dateTime={""} dateLastTime={""} />
          <AddMinPrice valueMinPrice={""} />
          <AddMaxPrice valueMaxPrice={""} />
          <AddLinkForm valueLinkTable={""} />
        </div>

        <div className="name__of__event" style={{ backgroundColor: "#ffffff", marginTop: 10 }}>
          <div className="add__text">
            <div className="add__name__event" style={{ display: "flex", justifyContent: "center", borderRadius: 40 }}>
              <AddName addName={""} />
            </div>
            <div className="add__description__event" style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
              <AddDescription addDescription={""} />
            </div>
            <div className="add__concert__programm__event" style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
              <AddConcertProgramm addConcertProgramm={""} />
            </div>
          </div>

          <div className="add__upload" style={{ color: "#000000", marginBottom: -15 }}>
            <div className="add__url__link">
              <AddUrlLink addUrl={""} />
            </div>

            <div className="add__color__button">
              <AddColorButton colorBkg={"#ffcc00"} colorTxt={"#000000"} />
            </div>

            <hr className="border__line__upload" />

            <div className="add__link__video">
              <AddLinkVideo videoHead={""} videoBody={""} />
            </div>

            <hr className="border__line__upload" />
            <div className="upload__file">
              <Typography className="title__upload">Изображение названия мероприятия</Typography>
              <FileUpload pathToSave={'logo'} onFileChange={updateLogo} />
            </div>
            <hr className="border__line__upload" />
            <div className="upload__file">
              <Typography className="title__upload">Изображение в шапку</Typography>
              <FileUpload pathToSave={'banner'} onFileChange={updateBanner} />
            </div>
            <hr className="border__line__upload" />
            <div className="upload__file">
              <Typography className="title__upload">Привью для главной страницы</Typography>
              <FileUpload pathToSave={'preview'} onFileChange={updatePreview} />
            </div>
            <hr className="border__line__upload" />
            <div className="upload__file">
              <Typography className="title__upload">Галерея кратное трём</Typography>
              <FileUpload pathToSave={'gallary'} onFileChange={updateGalary} />
            </div>
          </div>
        </div>

        <div className="add__seo__event" style={{ backgroundColor: "#ffffff", borderRadius: 10, marginTop: 10, }}>
          <AddTitleSeo seoTitle={""} />
          <AddDescriptionSeo seoDescription={""} />
        </div>

        {!isSend ?
          <button className="btn__send__admin__form" onClick={handleSubmint}>Добавить ивент</button>
          :
          <button className="btn__sended__admin__form" onClick={handleSubmint}>Добавлено</button>
        }
      </div>
    </>
  )
}

export default AdminAdd;

