// fileupload.js

import React, { useRef, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import ClearIcon from '@mui/icons-material/Clear';
import { pathBannerChange, pathLogoChange, pathPhotoChange, pathPreviewChange } from '../../features/savePathFile/photo';
// import { triggerChange } from '../../features/savePathFile/photo';
import "./fileUpload.css"
import inMemoryJWT from '../../service/inMemoryJWT';

function FileUpload({ pathToSave, onFileChange, setPhotos, setImg, setBanner, setPrew, setGalary }) {
  // для хранения загруженного файла
  const dispath = useDispatch();
  const name = useSelector((state) => state.getMinPrice.urlLink);
  const date = useSelector((state) => state.getDate.value);
  const gallery = useSelector((state) => state.checkEvent.pathGallary);
  const [file, setFile] = useState("");
  const [images, setImages] = useState([]);
  const [isValue, setValue] = useState(false);
  const [isData, setIsData] = useState([]);
  const [getData, setGetData] = useState([]);
  const [isPhoto, setIsPhoto] = useState(true);
  const [isImage, setIsImage] = useState("");
  const urlApi = process.env.REACT_APP_API_URL;
  
  let resultData = 0;

  const el = useRef(); // для доступа к инпуту
  const handleChange = (e) => {
    const file = e.target.files; // доступ к файлу
    setFile(file); // сохранение файла    
  }

  const ResourceClient = axios.create({
    baseURL: `${urlApi}`,
  });

  ResourceClient.interceptors.request.use(
    (config) => {
      const accessToken = inMemoryJWT.getToken();
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  const uploadFile = () => {
    if (file.length === 0) return;
    const formData = new FormData();
    formData.append('date', date);
    formData.append('name', name);
    for (let i = 0; i < file.length; i++) {
      formData.append(pathToSave, file[i]); // добавление файла
    }

    ResourceClient.post(`${urlApi}/api/uploadImage/`, formData).then(res => {
      onFileChange(res.data);
      resultData += res.data.length
      setValue(res.data.length)
      setIsData(res.data);
    }
    ).catch(err => console.log(err));
    console.log();
  };

  const handleDelete = async (image) => {
    const formData = new FormData();
    formData.append('pathToDelete', image);

    const response = await ResourceClient.post(`${urlApi}/api/deleteFile/`, formData).then(res => {
      onFileChange(res.data);
      // console.log(res.data)
      const responseData = Array.isArray(res.data) ? res.data : [];
      setIsPhoto(false);
      setIsData(responseData);
    }).catch(err => console.log(err));
    // console.log(image)

  };

  const handleFileChange = (event) => {
    const fileList = event.target.files;
    const imagesArray = Array.from(fileList).map((file) =>
      URL.createObjectURL(file),
    );
    setImages(imagesArray);
  };

  const handleClick = () => {
    setValue(false);
  };

  React.useEffect(() => {
    setGetData(setPhotos);
    // console.log(setImg)
    if (setImg) {
      dispath(pathLogoChange(setImg));
      setIsData(setImg)
    };
    if (setBanner) {
      dispath(pathBannerChange(setBanner));
      setIsData(setBanner)
    };
    if (setPrew) {
      dispath(pathPreviewChange(setPrew));
      setIsData(setPrew)
    };

    if (setGalary) {
      dispath(pathPhotoChange(setGalary));
      setIsData(setGalary)
    };

  }, [setPhotos, setImg, setBanner, setPrew, setGalary]);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", marginTop: 15 }}>
        <div className="add__photo">
          <input type="file" ref={el} onClick={handleClick} onChange={(e) => { handleChange(e); handleFileChange(e) }} multiple />
          <div className="container__photo">

            {console.log(isData)}
            {isData?.map((image, index) => (
              <div className="img__container" key={index}>
                <img
                  className="load__img"
                  key={index}
                  src={`${urlApi}` + image}
                  alt={`Image ${index}`}
                  style={{
                    width: 200,
                    height: 200,
                    objectFit: "cover",
                  }}
                />

                <div className="btn__img__container">
                  <button className="btn__delete__img" onClick={() => handleDelete(image)}>
                    <ClearIcon />
                  </button>
                </div>
              </div>
            ))}

          </div>
        </div>

        <div className="load__btn__container">
          {isValue ?
            <div className="btn__photo__complete">
              <button className="btn__complete__photo" onClick={uploadFile}>Загружено</button>
            </div>
            :
            <div className="btn__photo__uncomplete">
              <button className="btn__uncomplete__photo" onClick={uploadFile}>Загрузить</button>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default FileUpload;