import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { getMaxPrice } from '../../features/api/getMaxPrice';


export default function AddMaxPrice({ valueMaxPrice }) {
  const [maxPrice, setMaxPrice] = React.useState("");
  const dispatch = useDispatch();

  React.useEffect(() => {
    setMaxPrice(valueMaxPrice)
    dispatch(getMaxPrice(valueMaxPrice))
  }, [valueMaxPrice]);

  return (
    <div className="add__max__price">
      <Box
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch', bgcolor: "white" },
        }}
        noValidate
        autoComplete="off"
      >
        {/* Максимальная стоимость */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            id="outlined-basic"
            value={maxPrice}
            onChange={(e) => {
              setMaxPrice(e.target.value);
              dispatch(getMaxPrice(e.target.value))
            }}
            label="Максимальная стоимость"
            variant="outlined"
          />
        </div>
      </Box>
    </div>
  );
}
