import React from "react";
import { Helmet } from "react-helmet";
import "../scss/App.scss";

import BackgroundImage from "../assets/Unlimited/bg.png";
import Logo from "../assets/Unlimited/logo.png";

import gps from '../assets/icons/gps.png';
import ticket from '../assets/icons/ticket.png';
import time from '../assets/icons/time.png';

import BuyButton from "../components/BuyButton/BuyButton";

import img1 from "../assets/Unlimited/images/1.jpg";
import img2 from "../assets/Unlimited/images/2.jpg";
import img3 from "../assets/Unlimited/images/3.jpg";
import img4 from "../assets/Unlimited/images/4.jpg";
import img5 from "../assets/Unlimited/images/5.jpg";
import img6 from "../assets/Unlimited/images/6.jpg";

import { motion } from "framer-motion";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";

import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { incrementByAmount } from "../features/city/cityChoicer";

// настройки анимации элементов
const Animation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
};

function Unlimited() {
  const dispatch = useDispatch();
  dispatch(incrementByAmount("Старый Оскол"));
  // const [eventData, setEventData] = React.useState(false); // Создаем переменную состояния "eventData" и функцию для ее обновления "setEventData"
  // const { id } = useParams(); // Получаем параметр "id" из URL

  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(`http://localhost:1337/api/events/${id}?populate=*`); // Отправляем GET-запрос на сервер для получения данных
  //       const jsonData = await response.json(); // Преобразуем ответ в формат JSON
  //       setEventData(jsonData.data); // Обновляем состояние "eventData" с помощью полученных данных
  //     } catch (error) {
  //       console.error('Ошибка при получении данных:', error); // В случае ошибки выводим сообщение об ошибке в консоль
  //     }
  //   };

  //   fetchData(); // Вызываем функцию fetchData при каждом рендере компонента или изменении параметра "id"
  // }, [id]);

  return (
    <>
      <Helmet>
        <title>Купить билеты на музыкальное шоу «UNLIMITED»</title>
        <meta
          name="description"
          content="Купить билеты на концерты Старый Оскол! Оркестр «RCO» - представляет
          концертную программу «UNLIMITED» 16 Апреля. Удобные схемы залов, электронные билеты.
          Легко найти интересующий вас концерт, выбрать место в зале и оформить заказ всего в несколько кликов.
          Наша платформа обеспечивает удобный и безопасный способ покупки билетов онлайн. Электронные билеты cразу после оплаты."
        />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Купить билеты на музыкальное шоу «UNLIMITED»"></meta>
        <meta property="og:url" content="https://билетов.рус/Unlimited" />
        <meta property="og:description" content=" Вас ожидает захватывающее музыкальное шоу на границе музыкальных направлений.  В исполнении оркестра «RCO» прозвучат мировые рок  хиты, легендарные саундтреки, музыка из компьютерных игр и классическая музыка в современной обработке." />
        <meta property="og:image" content={`https://билетов.рус${BackgroundImage}`}></meta>

      </Helmet>
      <Header /> {/* Компонент Header */}
      <div className="main">
        <img
          className="background__image"
          src={BackgroundImage}
          alt="Фоновая картинка мероприятия"
        />
        <img className="main__logo" src={Logo} alt="Логотип мероприятия" />

        <motion.section
          initial="hidden"
          whileInView="visible"
          className="textblock"
        >
          <motion.p
            variants={Animation}
            custom={1}
            className="textblock__title"
          >
            Оркестр «RCO» - представляет <br></br> концертную программу «UNLIMITED».
          </motion.p>
          <motion.p variants={Animation} custom={1} className="textblock__desc">
            Вас ожидает захватывающее музыкальное шоу на границе
            музыкальных направлений.  В исполнении оркестра «RCO» прозвучат мировые рок
            хиты, легендарные саундтреки, музыка из компьютерных игр и классическая музыка в современной
            обработке. <br /> <br />Концерт подойдет для
            самых разных возрастов. Вы совершенно свободно можете пригласить на
            концерт своих родителей или детей и прекрасно провести время всей
            семьей. От нашей концертной программы в восторге будут все.
          </motion.p>
        </motion.section>
        <motion.section
          initial="hidden"
          whileInView="visible"
          className="iconblock"
        >
          <motion.div
            variants={Animation}
            custom={1}
            className="iconblock__item"
          >
            <img className="iconblock__item__img" src={gps} />
            <h3 className="iconblock__item__title">«ЦМИ»</h3>
            <p className="iconblock__item__p">ул. Ленина д.20</p>
          </motion.div>
          <motion.div
            variants={Animation}
            custom={2}
            className="iconblock__item"
          >
            <img className="iconblock__item__img" src={time} />
            <h3 className="iconblock__item__title">16 АПРЕЛЯ</h3>
            <p className="iconblock__item__p">19:00 / 20:20</p>
          </motion.div>
          <motion.div
            variants={Animation}
            custom={3}
            className="iconblock__item"
          >
            <img className="iconblock__item__img" src={ticket} />
            <h3 className="iconblock__item__title">ЦЕНА БИЛЕТА</h3>
            <p className="iconblock__item__p">1500-2300 ₽</p>
          </motion.div>
        </motion.section>
        <BuyButton
          link={
            "https://xn--90acilpt1a.xn--p1acf/wp/tc-events/simfo-show-unlimited/"
          }
        />

<span className="concertProg">
          <span className="concertProgName">
            <br /><br /> Концертная программа
              <p className="vozrast">Возрастной рейтинг: <span className="vozrast6">12+</span></p>
          </span>
        </span>
        <motion.section
          initial="hidden"
          whileInView="visible"
          className="textblock"
        >
          <motion.p variants={Animation} custom={1} className="textblock__desc">
            Iron Maiden - "The Trooper" <br></br>
            Castelvania - (музыка из видеоигры) <br></br>
            AC/DC - "Highway to hell" <br></br>
            Forrest Gump - (саундтрек из кинофильма) <br></br>
            Mega man - (музыка из видеоигры) <br></br>
            Миссия невыполнима - (саундтрек из кинофильма) <br></br>
            Гравити Фолз - (музыка из мультфильма) <br></br>
            Миссия - (саундтрек из фильма "Гобой Габриэля")<br></br>
            Miserlou - (саундтрек из кинофильма "Криминальное чтиво") <br></br>
            DOTA 2 - (музыка из видео игры) <br></br>
            The Prodigy - (оригинальная версия "Voodoo people") <br></br>
            Nirvana - "Smells Like Teen Spirit" <br></br>
            Scorpions - Wind of Change <br></br>
            Boney M - "Rasputin" <br></br>
            Boney M - "Sunny"<br></br>
            Русская народная песня - Ой, то не вечер... (оригинальная версия)
          </motion.p>
        </motion.section>
        <BuyButton
          link={
            "https://xn--90acilpt1a.xn--p1acf/wp/tc-events/simfo-show-unlimited/"
          }
        />

<iframe className="video" src="https://vk.com/video_ext.php?oid=-223778847&id=456239018&hd=2" allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameborder="0" allowfullscreen></iframe>
        <motion.section
          initial="hidden"
          whileInView="visible"
          class="grid-container"
        >
          <motion.img
            variants={Animation}
            custom={1.5}
            class="grid-item"
            src={img1}
          />
          <motion.img
            variants={Animation}
            custom={1.5}
            class="grid-item"
            src={img2}
          />
          <motion.img
            variants={Animation}
            custom={1.5}
            class="grid-item"
            src={img3}
          />
          <motion.img
            variants={Animation}
            custom={1.5}
            class="grid-item"
            src={img4}
          />
          <motion.img
            variants={Animation}
            custom={1.5}
            class="grid-item"
            src={img5}
          />
          <motion.img
            variants={Animation}
            custom={1.5}
            class="grid-item"
            src={img6}
          />
        </motion.section>
        <BuyButton link="https://xn--90acilpt1a.xn--p1acf/wp/tc-events/simfo-show-unlimited/" />{" "}
        {/* Компонент BuyButton */}
        <p className="footerText">
          Организатор мероприятия: <br /> ИП Пастухов Константин Геннадьевич <br />
          ИНН 312800384146 <br />
          ОГРНИП 304312815600139<br />
          Юридический адрес:<br />
          305018 г. Старый Оскол, м-н. Молодогвардеец, д.16, оф. 89.
        </p>
      </div>
      <Footer /> {/* Компонент Footer */}
    </>
  );
  // jsx для динамической подгрузки
  // return (
  //   <>
  //     <Header /> {/* Компонент Header */}
  //     {eventData ? (
  //       <>
  //         <div className="main">
  //           <img
  //             className="background__image"
  //             src={`http://localhost:1337${eventData.attributes.Background.data.attributes.formats.large.url}`}
  //             alt="Фоновая картинка мероприятия"
  //           />
  //           <img
  //             className="main__logo"
  //             src={`http://localhost:1337${eventData.attributes.Logo.data.attributes.url}`}
  //             alt="Логотип мероприятия"
  //           />
  //           <p>ВОЗРАСТНОЙ РЕЙТИНГ: {eventData.attributes.age_rating.data.attributes.Name}</p>
  //           <BuyButton link={eventData.attributes.BuyLink} /> {/* Компонент BuyButton */}
  //           <motion.section initial="hidden" whileInView="visible" className="textblock">
  //             <motion.p variants={Animation} custom={1} className="textblock__title">
  //               {eventData.attributes.Description}
  //             </motion.p>
  //           </motion.section>
  //           <motion.section initial="hidden" whileInView="visible" className="iconblock">
  //             <motion.div variants={Animation} custom={1} className="iconblock__item">
  //               <img className="iconblock__item__img" src={gps} />
  //               <h3 className="iconblock__item__title">
  //                 {eventData.attributes.location.data.attributes.hallName}
  //               </h3>
  //               <p className="iconblock__item__p">
  //                 {eventData.attributes.location.data.attributes.Location}
  //               </p>
  //             </motion.div>
  //             <motion.div variants={Animation} custom={2} className="iconblock__item">
  //               <img className="iconblock__item__img" src={time} />
  //               <h3 className="iconblock__item__title">{eventData.attributes.Date}</h3>
  //               <p className="iconblock__item__p">
  //                 {eventData.attributes.StartTime} / {eventData.attributes.EndTime}
  //               </p>
  //             </motion.div>
  //             <motion.div variants={Animation} custom={3} className="iconblock__item">
  //               <img className="iconblock__item__img" src={ticket} />
  //               <h3 className="iconblock__item__title">ЦЕНА БИЛЕТА</h3>
  //               <p className="iconblock__item__p">{eventData.attributes.Price}</p>
  //             </motion.div>
  //           </motion.section>
  //           <BuyButton link={eventData.attributes.BuyLink} /> {/* Компонент BuyButton */}
  //           <motion.section initial="hidden" whileInView="visible" class="grid-container">
  //             {eventData.attributes.Images.data.map((image) => (
  //               <motion.img
  //                 variants={Animation}
  //                 custom={1.5}
  //                 class="grid-item"
  //                 alt={image.attributes.name}
  //                 src={`http://localhost:1337${image.attributes.url}`}
  //               />
  //             ))}
  //           </motion.section>
  //           <BuyButton link={eventData.attributes.BuyLink} /> {/* Компонент BuyButton */}
  //           <p className="footerText">
  //             Организатор мероприятия:
  //             {eventData.attributes.organizer_detail.data.attributes.Value}
  //             {eventData.attributes.organizer_detail.data.attributes.INN}
  //           </p>
  //         </div>
  //         <Footer /> {/* Компонент Footer */}
  //       </>
  //     ) : (
  //       <></>
  //     )}
  //   </>
  // );
}

export default Unlimited;
