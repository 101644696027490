import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

import BuyButton from "../components/BuyButton/BuyButton";

import { motion } from "framer-motion";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";


import { incrementByAmount } from "../features/city/cityChoicer";


import axios from 'axios';
import "../scss/App.scss";


const Animation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
};
const urlApi = process.env.REACT_APP_API_URL;

export default function Event() {
  const { urlLending, city } = useParams();
  const [isEvent, setEvent] = useState(null);
  const [isCity, setIsCity] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${urlApi}/api/getEvent/${urlLending}`);
      if(response.data.location.city.translit != city){
        return navigate("/404")
      }
      dispatch(incrementByAmount(response.data.location.city.name));
      setEvent(response.data);
    } catch (e) {
      return navigate("/404")
    };
  };

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const optionsTime = {
    hour: 'numeric',
    minute: 'numeric',
  };

  useEffect(() => {
    if (!isEvent) {
      fetchEvents();
    }
    return;
  }, [urlLending, isCity]);


  return (
    <div>
      {isEvent && (
        <>
          <div>
            <Helmet>
              <title>{JSON.parse(isEvent.seo).title}</title>
              <meta
                name="description"
                content={JSON.parse(isEvent.seo).description}
              />
              <meta property="og:type" content="website" />
              <meta property="og:title" content={JSON.parse(isEvent.seo).title}></meta>
              <meta property="og:url" content={"https://билетов.рус/" + urlLending} />
              <meta property="og:description" content={JSON.parse(isEvent.seo).description} />
              <meta property="og:image" content={`https://билетов.рус${isEvent.banner}`}></meta>
            </Helmet>

            <Header /> {/* Компонент Header */}
            <div className="main">
              <img
                className="background__image"
                src={"https://билетов.рус/" + isEvent.banner}
                alt="Фоновая картинка мероприятия"
              />

              <img className="main__logo" src={"https://билетов.рус/" + isEvent.logo} alt="Логотип мероприятия" />

              {/* Компонент BuyButton */}
              <motion.section
                initial="hidden"
                whileInView="visible"
                className="textblock"
              >
                <motion.div
                  variants={Animation}
                  custom={1}
                  className="textblock__title"
                >
                  <div dangerouslySetInnerHTML={{ __html: isEvent.name }} />
                </motion.div>
                <motion.div variants={Animation} custom={1} className="textblock__desc">
                  <div dangerouslySetInnerHTML={{ __html: isEvent.description }} />
                </motion.div>
              </motion.section>                
              {!isEvent.typeEvent.multi ? (
              <motion.section
                initial="hidden"
                whileInView="visible"
                className="iconblock"
              >


                <motion.div
                  variants={Animation}
                  custom={1}
                  className="iconblock__item"
                >
                  <svg version="1.1" id="Слой_3" className="iconblock__item__img" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 64.7 64.7" style={{ enableBackground: 'new 0 0 64.7 64.7' }} xmlSpace="preserve">
                    <style>
                      {
                        `.st0{ fill: ${isEvent.backgroundColorButton}; }
                      .st1{ fill: none; }`
                      }
                    </style>
                    <path className="st0" d="M46.9,25.2c-0.7-3.6-2.7-6.8-5.6-9c-2-1.6-4.5-2.6-7-2.9L33,13.2h-1.7h-0.2c-0.8,0.1-1.6,0.2-2.4,0.4
                    c-6.8,1.6-11.5,7.6-11.5,14.6c0,1.4,0.3,2.8,0.9,4.2c0.9,2.5,2.1,4.8,3.5,7.1c2.9,4.8,6.2,9.4,9.8,13.7c0.3,0.5,0.9,0.6,1.4,0.3
                    c0.1-0.1,0.3-0.2,0.3-0.3c0.6-0.8,1.3-1.6,1.9-2.4c2.7-3.4,5.2-6.9,7.4-10.6c1.4-2.3,2.6-4.6,3.7-7.1c0.5-1.2,0.9-2.5,1.1-3.7
                    C47.2,27.9,47.2,26.6,46.9,25.2z M38.6,27.2c-0.1,3.4-3,6.1-6.4,6c-3.4-0.1-6.1-3-6-6.4c0.1-3.4,2.9-6,6.2-6c3.4,0,6.2,2.8,6.2,6.3
                    C38.6,27.1,38.6,27.2,38.6,27.2z"/>
                    <rect className="st1" width="64.7" height="64.7" />
                  </svg>

                  <h3 className="iconblock__item__title">{isEvent.location.place}</h3>
                  <p className="iconblock__item__p">{isEvent.location.street + " " + isEvent.location.house}</p>
                </motion.div>
                  
                <motion.div
                  variants={Animation}
                  custom={2}
                  className="iconblock__item"
                >
                  <svg version="1.1" id="Слой_2" className="iconblock__item__img" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 64.7 64.7" style={{ enableBackground: 'new 0 0 64.7 64.7' }} xmlSpace="preserve">
                    <style type="text/css">
                      {
                        `.st2{fill: ${isEvent.backgroundColorButton};}
                        .st3{fill: none;}`
                      }
                    </style>
                    <path className="st2" d="M32.4,13.8c-9.7,0-17.5,7.8-17.5,17.5s7.8,17.5,17.5,17.5S49.9,41,49.9,31.4c0,0,0,0,0,0
	                  C49.9,21.7,42,13.8,32.4,13.8z M44.9,33.5H32.3c-0.6,0-1.1-0.5-1.1-1.1V19.2c-0.1-0.6,0.4-1.2,1-1.2c0.6-0.1,1.2,0.4,1.2,1
	                  c0,0.1,0,0.1,0,0.2v12h11.5c0.6,0,1.1,0.5,1.1,1.1C46,32.9,45.5,33.4,44.9,33.5L44.9,33.5L44.9,33.5z"/>
                    <rect className="st3" width="64.7" height="64.7" />
                  </svg>

                  <h3 className="iconblock__item__title">{new Date(isEvent.dateTime).toLocaleString("ru", options).toUpperCase()}</h3>
                  <p className="iconblock__item__p">{`${new Date(isEvent.dateTime).toLocaleString("ru", optionsTime)} / ${isEvent.duration} `}</p>
                </motion.div>

                <motion.div
                  variants={Animation}
                  custom={3}
                  className="iconblock__item"
                >
                  <svg version="1.1" id="Слой_1" className="iconblock__item__img" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 64.7 64.7" style={{ enableBackground: "new 0 0 64.7 64.7" }} xmlSpace="preserve">
                    <style type="text/css">
                      {
                        `.st4{fill: ${isEvent.backgroundColorButton};}
                        .st5{fill: none;}`
                      }
                    </style>
                    <path className="st4" d="M55.1,32.4c0-2.3,1.8-4.1,4.1-4.1c0,0,0,0,0,0v-8H5.5v8c2.3,0,4.1,1.8,4.1,4.1s-1.8,4.1-4.1,4.1l0,0v8h53.8v-8
	                  C57,36.5,55.1,34.6,55.1,32.4L55.1,32.4z"/>
                    <rect className="st5" width="64.7" height="64.7" />
                  </svg>


                  <h3 className="iconblock__item__title">ЦЕНА БИЛЕТА</h3>
                  <p className="iconblock__item__p">{`${isEvent.minPrice}-${isEvent.maxPrice} ₽`}</p>
                </motion.div>
              </motion.section>
              ) : ('')} 
              <BuyButton
                colorAdd={isEvent.backgroundColorButton}
                textColor={isEvent.colorButton}
                link={isEvent.urlEvent}
              />

              <br />
              <span className="concertProg">
                <span className="concertProgName">
                {!isEvent.typeEvent.multi ?("Концертная программа") : ""}
                  <p className="vozrast">Возрастной рейтинг: <span className="vozrast6">+{isEvent.rating.age}</span></p>
                </span>
              </span>
              <motion.section
                initial="hidden"
                whileInView="visible"
                className="textblock">
                <motion.div
                  variants={Animation}
                  custom={1}
                  className="textblock__desc"
                >
                  <div dangerouslySetInnerHTML={{ __html: isEvent.concertProgramm }} />
                </motion.div>
              </motion.section>

              <BuyButton
                colorAdd={isEvent.backgroundColorButton}
                textColor={isEvent.colorButton}
                link={isEvent.urlEvent}
              />

              {/* {console.log(JSON.parse(isEvent.videoBody)[0].ownerId)} */}
              {/* <iframe className="video" src={`https://vk.com/video_ext.php?oid=-${JSON.parse(isEvent.videoHead)[0].ownerId}&id=${JSON.parse(isEvent.videoHead)[0].videoId}&hd=2`} width="1110" height="480" allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameborder="0" allowfullscreen></iframe> */}
              {isEvent.videoBody ?
                <iframe className="video" src={`https://vk.com/video_ext.php?oid=${JSON.parse(isEvent.videoBody)[0].ownerId}&id=${JSON.parse(isEvent.videoBody)[0].videoId}&hd=2`} width="1110" height="480" allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameborder="0" allowfullscreen></iframe>
                :
                ''
              }
              <motion.section
                initial="hidden"
                whileInView="visible"
                className="grid-container"
              >
                {isEvent.gallaryPhoto.map((e) => (
                  <motion.img
                    variants={Animation}
                    custom={1.5}
                    className="grid-item"
                    src={"https://билетов.рус/" + e}
                  />
                ))
                }
              </motion.section>
              {!isEvent.typeEvent.multi ?(
              <BuyButton
                colorAdd={isEvent.backgroundColorButton}
                textColor={isEvent.colorButton}
                link={isEvent.urlEvent}
              />): "" }
              {/* Компонент BuyButton */}
              <div className="footerText">
                Организатор мероприятия: <p>{isEvent.organizer.name}</p>  <p> ИНН: {isEvent.organizer.inn}</p>
              </div>
            </div>
            <Footer />
          </div>
        </>
      )
      }
    </div >
  )
}

