import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import EventCard from '../components/EventCard/EventCard';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import { useDispatch } from "react-redux";
import { incrementByAmount } from "../features/city/cityChoicer";
import { eventCardMain } from '../features/eventCard/eventCard';

// Потом удалить
import backgroundFire from '../assets/FireShow/backgroundFire.jpg';
import FireLogo from '../assets/FireShow/logoPtitsa.png';

import backgroundOrch from '../assets/Orchestral/bgcard.jpg';
import OrchLogo from '../assets/Orchestral/logo.png';

import backgroundRaz from '../assets/Razoblachenie/preview.jpg';
import RazLogo from '../assets/Razoblachenie/logo.png';


// компонент домашней страницы
const HomePage = () => {
  const [cards, setCards] = useState([]);
  const dispatch = useDispatch();
  const urlApi = process.env.REACT_APP_API_URL;
  dispatch(incrementByAmount('Курск'));

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${urlApi}/api/getEvent/city/kursk`);
      dispatch(eventCardMain(response.data))
      setCards(response.data)
    } catch (e) {
      console.log(e)
    };
  };

  const optionsDate = {
    month: 'long',
    day: 'numeric',
  };

  const optionsTime = {
    hour: 'numeric',
    minute: 'numeric',
  };

  const optionsWeekday = {
    weekday: 'long',
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  var _tmr = window._tmr || (window._tmr = []); _tmr.push({ type: 'reachGoal', id: 3467367, goal: 'view_main_page' });
  return (
    <>
      <Helmet>
        <title>Купить билеты на концерты в Курске</title>
        <meta
          name="description"
          content="Купить билеты на концерты Курск! Удобные схемы залов, электронные билеты.
          Легко найти интересующий вас концерт, выбрать место в зале и оформить заказ всего в несколько кликов.
          Наша платформа обеспечивает удобный и безопасный способ покупки билетов онлайн. Электронные билеты cразу после оплаты."
        />
      </Helmet>
      <div className="page">
        <Header /> {/* Компонент Header */}
        <div className="centering">
          <div className="centering__cards">
          {/* <EventCard
              price={'1000-2000 ₽'}
              data={'5 МАРТА 19 : 00 ВТОРНИК'}
              location={'КЗ «СВИРИДОВСКИЙ» ул. Сонина, 4'}
              buttonlink={'https://xn--90acilpt1a.xn--p1acf/wp/tc-events/firebird/'}
              routerlink="/FireBird"
              eventimage={backgroundFire}
              eventlogo={FireLogo}
            /> */}
            {/* <EventCard
              price={'500-1200 ₽'}
              data={'20 МАРТА 19 : 00 СРЕДА'}
              location={'КЗ «СВИРИДОВСКИЙ» ул. Сонина, 4'}
              buttonlink={'https://xn--90acilpt1a.xn--p1acf/wp/tc-events/orchestral-masterpieces/'}
              routerlink="/Orchestral"
              eventimage={backgroundOrch}
              eventlogo={OrchLogo}
            /> */}
            {/* <EventCard
              price={'750 ₽'}
              data={'3, 8, 10 АПРЕЛЯ 19 : 00'}
              location={'КЗ «СВИРИДОВСКИЙ» ул. Сонина, 4'}
              buttonlink={'https://билетов.рус/wp/tc-events/orkestr-razoblachenie-3-koncerta-lekciy/'}
              routerlink="/kursk/razoblachenie-2024"
              eventimage={backgroundRaz}
              eventlogo={RazLogo}
            /> */}

            {/* {cards?.map((post) => (
              <EventCard
                key={post._id}
                price={`${post.minPrice}-${post.maxPrice} ₽`}
                data={`${new Date(post.dateTime).toLocaleString("ru", optionsDate).toUpperCase()}
                 ${new Date(post.dateTime).toLocaleString("ru", optionsTime)}
                  ${new Date(post.dateTime).toLocaleString("ru", optionsWeekday).toUpperCase()}`}
                location={`${post.location.place} ${post.location.street} ${post.location.house}`}
                buttonlink={`${post.urlEvent}`}
                routerlink={`/${post.location.city.translit}/${post.urlLending}`}
                eventimage={`${post.previewPhoto}`}
                eventlogo={`${urlApi}` + post.logo}
              />
            ))
            } */}
            
          </div>
        </div>
      </div>
      {/* Футер сайта */}
      <Footer />
    </>
  );
};

export default HomePage;





{
  /* <EventCard
   price={'1000-2000 ₽'}
   data={'5 МАРТА 19 : 00 ВТОРНИК'}
   location={'КЗ «СВИРИДОВСКИЙ» ул. Сонина, 4'}
   buttonlink={'https://xn--90acilpt1a.xn--p1acf/wp/tc-events/firebird/'}
   routerlink="/FireBird"
   eventimage={backgroundFire}
   eventlogo={FireLogo}
 />

 <EventCard
   price={'500-1200 ₽'}
   data={'20 МАРТА 19 : 00 СРЕДА'}
   location={'КЗ «СВИРИДОВСКИЙ» ул. Сонина, 4'}
   buttonlink={'https://xn--90acilpt1a.xn--p1acf/wp/tc-events/orchestral-masterpieces/'}
   routerlink="/Orchestral"
   eventimage={backgroundOrch}
   eventlogo={OrchLogo}
 /> */
}