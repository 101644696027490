import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { getUrl } from '../../features/api/getMinPrice';
import Typography from '@mui/material/Typography';
import "./EventsStyles.css";

export default function AddUrlLink({ addUrl }) {
  const [link, setLink] = React.useState("");
  const dispatch = useDispatch();

  React.useEffect(() => {
    setLink(addUrl)
    dispatch(getUrl(addUrl))
  }, [addUrl])

  return (
    <div className="add__link">
      <Box
        sx={{ '& .MuiTextField-root': { m: 1, width: '25ch', bgcolor: "white" }, }}
        noValidate
        autoComplete="off"
      >
        {/* Ссылка */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10 }}>
            <label>
              <Typography
                variant="a"
                sx={{
                  mr: 2,
                  color: "black",
                  fontWeight: 700,
                  fontSize: 20,
                  display: "flex",
                  justifyContent: "center",
                  letterSpacing: '.1rem',
                  textDecoration: 'none',
                  marginBottom: 2,
                }}
              >
                URL страницы лендинга
              </Typography>
              <TextField
                size="small"
                style={{ width: 700, margin: 0 }}
                className="add__url__link"
                id="outlined-basic"
                value={link}
                onChange={(e) => {
                  setLink(e.target.value);
                  dispatch(getUrl(e.target.value))
                }}
                label="URL-lending"
                variant="outlined"
              />
            </label>
          </div>
        </div>
      </Box>
    </div>
  )
}
