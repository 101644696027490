import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { getDescription } from '../../features/api/getDescription';
import { Editor } from '@tinymce/tinymce-react';


export default function AddDescription({ addDescription }) {
  const [description, setDescription] = React.useState("");
  const [editior, setEditior] = React.useState(false);
  const [isContent, setNewContent] = React.useState("");
  const editorRef = React.useRef(null);
  const dispatch = useDispatch();

  const handleSend = (e) => {
    if (isContent.trim() === "") return;
    setDescription(editorRef.current.getContent());
    dispatch(getDescription(editorRef.current.getContent()));
    setEditior(false);
  };

  const handleClick = (e) => {
    setEditior(e.currentTarget);
  };

  React.useEffect(() => {
    setEditior(true);
    dispatch(getDescription(addDescription));
  }, [])

  return (
    <div className="add__description">
      <Box
        sx={{ '& .MuiTextField-root': { m: 1, width: '25ch', bgcolor: "white" }, }}
        noValidate
        autoComplete="off"
      >
        {/* Описание */}
        <Typography
          variant="a"
          sx={{
            mr: 2,
            color: "black",
            fontWeight: 700,
            fontSize: 20,
            display: "flex",
            justifyContent: "center",
            letterSpacing: '.1rem',
            textDecoration: 'none',
            marginBottom: 2,
          }}
        >
          Описание
        </Typography>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Editor
            onEditorChange={(newContent) => setNewContent(newContent)}
            onClick={handleClick}
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={addDescription}
            init={{
              height: 500,
              width: 700,
              menubar: true,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help'
              ,
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size: 14px }'
            }}
          />
        </div>

        <div className="btn__block"
          style={{
            display: "flex",
            justifyContent: "end"
          }}
        >
          {editior ?
            <button
              onClick={handleSend}
              className="btn__description_uncomplete"
              variant="outlined"
            >
              Сохранить
            </button>
            :
            <button
              onClick={handleSend}
              className="btn__description_complete"
              variant="outlined"
            >
              Сохранено
            </button>
          }
        </div>
      </Box>
    </div>
  )
}
