import React from "react";
import "../scss/App.scss";
import Logo from "../assets/Disco/logo.png";
import BackgroundImage from "../assets/Hermes/bg.jpg";
import gps from '../assets/icons/gps.svg';
import ticket from '../assets/icons/ticket.svg';
import time from '../assets/icons/time.svg';

import BuyButton from "../components/BuyButton/BuyButton";

import img1 from "../assets/Disco/images/img1.jpg";
import img2 from "../assets/Disco/images/img2.jpg";
import img3 from "../assets/Disco/images/img3.jpg";
import img4 from "../assets/Disco/images/img4.jpg";
import img5 from "../assets/Disco/images/img5.jpg";
import img6 from "../assets/Disco/images/img6.jpg";

import { motion } from "framer-motion";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";

import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { incrementByAmount } from "../features/city/cityChoicer";
import Form from "../components/Form/Form";
import AddParticipant from "../components/AddParticipant/AddParticipant";
import { Helmet } from "react-helmet";

// настройки анимации элементов
const Animation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
};

function Hermes() {
  const dispatch = useDispatch();
  dispatch(incrementByAmount("Курск"));
  // const [eventData, setEventData] = React.useState(false); // Создаем переменную состояния "eventData" и функцию для ее обновления "setEventData"
  // const { id } = useParams(); // Получаем параметр "id" из URL

  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(`http://localhost:1337/api/events/${id}?populate=*`); // Отправляем GET-запрос на сервер для получения данных
  //       const jsonData = await response.json(); // Преобразуем ответ в формат JSON
  //       setEventData(jsonData.data); // Обновляем состояние "eventData" с помощью полученных данных
  //     } catch (error) {
  //       console.error('Ошибка при получении данных:', error); // В случае ошибки выводим сообщение об ошибке в консоль
  //     }
  //   };

  //   fetchData(); // Вызываем функцию fetchData при каждом рендере компонента или изменении параметра "id"
  // }, [id]);

  return (
    <>
      <Helmet>
        <title>«Бойцовское троеборие»</title>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="«Бойцовское троеборие»"></meta>
        <meta property="og:url" content="https://билетов.рус/Hermes" />
        <meta property="og:description" content="Это уникальное спортивное мероприятие, в котором каждый сможет проверить свои силы в беге, стрельбе и силовом нормативе." />
        <meta property="og:image" content={`https://билетов.рус${BackgroundImage}`}></meta>
      </Helmet>
      <Header /> {/* Компонент Header */}
      <div className="main">
        <img
          className="backgroundsport__image"
          src={BackgroundImage}
          alt="Фоновая картинка мероприятия"
        />
        {/* <div className="main__logosport">
        </div> */}
        <motion.section
          initial="hidden"
          whileInView="visible"
          className="textblock"
        >
          <motion.p
            variants={Animation}
            custom={1}
            className="textblock__titlesport"
          >
            <br />
            «Бойцовское троеборье»
          </motion.p>
          <motion.p variants={Animation} custom={1} className="textblock__desc">
            Это уникальное спортивное мероприятие, в котором каждый сможет проверить свои силы в беге, стрельбе и силовом нормативе. <br /><br />
            В программе:<br />
            ✅ Бег на 100 м и 2000 м,<br />
            cгибание-разгибание рук в упоре лёжа для женщин и девочек, стрельба<br />
            ✅ Бег на 100 м и 3000 м,<br />
            подтягивание на высокой перекладине (максимум за 1 минуту) для мужчин и мальчиков, стрельба<br /><br />
            Возрастные группы участников:<br />
            ✅ 12 лет и младше<br />
            ✅ 13-15 лет<br />
            ✅ 16-17 лет<br />
            ✅ 18-39 лет<br />
            ✅ 40 лет и старше<br /><br />
            Старт будет дан в помещении бегового манежа на стадионе "Трудовые резервы" 9 марта в 12:00.<br />
            Регистрация проводится до 7 марта включительно.<br /><br />
            По всем вопросам пишите организатору :<br />
            89081271127 - Владимир Мосичев<br /><br />
            Партнёрами мероприятия являются министерство спорта Курской области, федерация полиатолона Курской области, ГТО Курская область, СО "Трудовые Резервы", Беговое движение "Курск Бежит", магазин "Блокпост".<br /><br />
            У вас есть шанс проверить себя и свои силы независимо от возраста и физической подготовки, а также получить новые эмоции и впечатления.<br />
            Не упустите свой шанс!<br />
          </motion.p>
        </motion.section>
        <motion.section
          initial="hidden"
          whileInView="visible"
          className="iconblock"
        >
          <motion.div
            variants={Animation}
            custom={1}
            className="iconblock__item"
          >
            <img className="iconblock__item__img" src={gps} />
            <h3 className="iconblock__item__title">Стадион «Трудовые резервы»</h3>
            <p className="iconblock__item__p">г. Курск, ул. Ленина 58</p>
          </motion.div>
          <motion.div
            variants={Animation}
            custom={2}
            className="iconblock__item"
          >
            <img className="iconblock__item__img" src={time} />
            <h3 className="iconblock__item__title">9 МАРТА</h3>
            <p className="iconblock__item__p">11:00</p>
          </motion.div>
          <motion.div
            variants={Animation}
            custom={3}
            className="iconblock__item"
          >
            <img className="iconblock__item__img" src={ticket} />
            <h3 className="iconblock__item__title">ЦЕНА СЛОТА</h3>
            <p className="iconblock__item__p">300 ₽</p>
          </motion.div>
        </motion.section>
        

        {/* Регистрация участника */}
        <AddParticipant />

        {/* <Form /> */}

        {/* Компонент BuyButton */}
        <p className="footerText">
          Организатор мероприятия: <br />Мосичев Владимир Владимирович <br />
          ИНН 463239214214 <br />

        </p>
      </div>
      <Footer /> {/* Компонент Footer */}
    </>
  );
  // jsx для динамической подгрузки
  // return (
  //   <>
  //     <Header /> {/* Компонент Header */}
  //     {eventData ? (
  //       <>
  //         <div className="main">
  //           <img
  //             className="background__image"
  //             src={`http://localhost:1337${eventData.attributes.Background.data.attributes.formats.large.url}`}
  //             alt="Фоновая картинка мероприятия"
  //           />
  //           <img
  //             className="main__logo"
  //             src={`http://localhost:1337${eventData.attributes.Logo.data.attributes.url}`}
  //             alt="Логотип мероприятия"
  //           />
  //           <p>ВОЗРАСТНОЙ РЕЙТИНГ: {eventData.attributes.age_rating.data.attributes.Name}</p>
  //           <BuyButton link={eventData.attributes.BuyLink} /> {/* Компонент BuyButton */}
  //           <motion.section initial="hidden" whileInView="visible" className="textblock">
  //             <motion.p variants={Animation} custom={1} className="textblock__title">
  //               {eventData.attributes.Description}
  //             </motion.p>
  //           </motion.section>
  //           <motion.section initial="hidden" whileInView="visible" className="iconblock">
  //             <motion.div variants={Animation} custom={1} className="iconblock__item">
  //               <img className="iconblock__item__img" src={gps} />
  //               <h3 className="iconblock__item__title">
  //                 {eventData.attributes.location.data.attributes.hallName}
  //               </h3>
  //               <p className="iconblock__item__p">
  //                 {eventData.attributes.location.data.attributes.Location}
  //               </p>
  //             </motion.div>
  //             <motion.div variants={Animation} custom={2} className="iconblock__item">
  //               <img className="iconblock__item__img" src={time} />
  //               <h3 className="iconblock__item__title">{eventData.attributes.Date}</h3>
  //               <p className="iconblock__item__p">
  //                 {eventData.attributes.StartTime} / {eventData.attributes.EndTime}
  //               </p>
  //             </motion.div>
  //             <motion.div variants={Animation} custom={3} className="iconblock__item">
  //               <img className="iconblock__item__img" src={ticket} />
  //               <h3 className="iconblock__item__title">ЦЕНА БИЛЕТА</h3>
  //               <p className="iconblock__item__p">{eventData.attributes.Price}</p>
  //             </motion.div>
  //           </motion.section>
  //           <BuyButton link={eventData.attributes.BuyLink} /> {/* Компонент BuyButton */}
  //           <motion.section initial="hidden" whileInView="visible" class="grid-container">
  //             {eventData.attributes.Images.data.map((image) => (
  //               <motion.img
  //                 variants={Animation}
  //                 custom={1.5}
  //                 class="grid-item"
  //                 alt={image.attributes.name}
  //                 src={`http://localhost:1337${image.attributes.url}`}
  //               />
  //             ))}
  //           </motion.section>
  //           <BuyButton link={eventData.attributes.BuyLink} /> {/* Компонент BuyButton */}
  //           <p className="footerText">
  //             Организатор мероприятия:
  //             {eventData.attributes.organizer_detail.data.attributes.Value}
  //             {eventData.attributes.organizer_detail.data.attributes.INN}
  //           </p>
  //         </div>
  //         <Footer /> {/* Компонент Footer */}
  //       </>
  //     ) : (
  //       <></>
  //     )}
  //   </>
  // );
}

export default Hermes;
