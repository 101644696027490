import React from 'react';

import styles from './BuyButton.module.scss';

//import ym from 'react-yandex-metrika';

// компонент стилизованной кнопки
const BuyButton = ({ link, colorAdd, textColor, borderButton }) => {
  var _tmr = window._tmr || (window._tmr = []);
  return (
    <>
      <a
        href={link}
        onClick={() => {
          /* eslint-disable */
          ym(95657055,'reachGoal','click_buy_button')
          /* eslint-enable */
          console.log('click');
          _tmr.push({ type: 'reachGoal', id: 3467367, goal: 'buy_button_on_landing'});
        }}>
        <div className={styles.buttons}>
          <button className={styles.blob__btn} style={{color: textColor}}>
            КУПИТЬ БИЛЕТ
            <span className={styles.blob__btn__inner} style={{backgroundColor: colorAdd, border: borderButton}}>
              <span className={styles.blob__btn__blobs} >
                <span className={styles.blob__btn__blob}></span>
                <span className={styles.blob__btn__blob}></span>
                <span className={styles.blob__btn__blob}></span>
                <span className={styles.blob__btn__blob}></span>
              </span>
            </span>
          </button>
        </div>
      </a>
      <div style={{ position: 'absolute' }}>
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
          <defs>
            <filter id="goo">
              <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10"></feGaussianBlur>
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
                result="goo"></feColorMatrix>
              <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
            </filter>
          </defs>
        </svg>
      </div>
    </>
  );
};

export default BuyButton;
