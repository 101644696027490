import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { SnackbarProvider } from "notistack";

import { AuthContext } from "./context/AuthContex.jsx";

import SignIn from './pages/SignIn.jsx';

import FireBird from './pages/FireBird.jsx';
import Orchestral from './pages/Orchestral.jsx';
import Disco80 from './pages/Disco80.jsx';
import Unlimited from './pages/Unlimited.jsx'
import HomePageOskol from './pages/HomePageOskol.jsx';

import HomePage from './pages/HomePage.jsx';
import Hermes from './pages/Hermes.jsx';
import Admin from './pages/AdminAdd.jsx';
import AdminRoute from './utils/router/adminRoute.jsx'
import SignInRoute from './utils/router/signinRoute.jsx'
import Event from './pages/Event.jsx';

import ErrorPage from './pages/404.jsx';
import OtherHomePage from './pages/OtherHomePage.jsx';
import AdminUpdate from './pages/AdminUpdate.jsx';
import UpdateEvent from './pages/UpdateEvent.jsx';
import './scss/App.scss';

function App() {


  return (
    <>
      <SnackbarProvider />
      <Routes>
        
        <Route path="/FireBird" element={<FireBird />}></Route>
        <Route path="/Orchestral" element={<Orchestral />}></Route>
        <Route path="/Disco80" element={<Disco80 />}></Route>
        <Route path="/Unlimited" element={<Unlimited />}></Route>
        <Route path="/Oscol" element={<HomePageOskol />}></Route>

        <Route element={<SignInRoute />}>
          <Route path="/signin" element={<SignIn />}></Route>
        </Route>
        <Route element={<AdminRoute />}>
          <Route path="/Admin" element={<Admin />}></Route>
          <Route path="/Update" element={<AdminUpdate />}></Route>
          <Route path="/Update/:UpdateEvent" element={<UpdateEvent />}></Route> 
        </Route>

        <Route path="/FireBird" element={<FireBird />}></Route>
        <Route path="/Orchestral" element={<Orchestral />}></Route>
        <Route path="/Disco80" element={<Disco80 />}></Route>
        <Route path="/Unlimited" element={<Unlimited />}></Route>
        <Route path="/Hermes" element={<Hermes />}></Route>

        <Route path="/:city/:urlLending" element={<Event />}></Route>
        <Route path="/:city" element={<OtherHomePage />}></Route>

        <Route path="/" element={<HomePage />}></Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
