import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

import { useSelector, useDispatch } from "react-redux";
import styles from './EventCard.module.scss';
import { eventCardMain } from '../../features/eventCard/eventCard';

// карточка с эвентом расположенная на homepage
var _tmr = window._tmr || (window._tmr = []);
const EventCard = ({
  price,
  data,
  location,
  buttonlink,
  routerlink,
  eventimage,
  eventlogo,
  id,
}) => {
  return (
    <>
      <div className={styles.block}>
        <p className={styles.block__price}>{price}</p>
        <Link to={routerlink} className={styles.block__link}>
          <img
            className={styles.block__img}
            src={eventimage}
            alt="Фоновое изображение мероприятия"
          />
        </Link>
        <Link to={routerlink}>
          <img className={styles.block__logo} src={eventlogo} alt="Логотип мероприятия" />
        </Link>
        <div className={styles.textarea}>
          <div className={styles.textarea__zal__container}>
            <h4 className={styles.textarea__zal}>{location}</h4>
          </div>

          <div className={styles.textarea__date__container}>
            <h4 className={styles.textarea__date}>{data}</h4>
          </div>
        </div>
        <a href={buttonlink} onClick={() => {
          _tmr.push({ type: 'reachGoal', id: 3467367, goal: 'buy_button_on_landing' });
          /* eslint-disable */
          ym(95657055, 'reachGoal', 'click_buy_button');
          /* eslint-enable */
        }}>
          <button className={styles.bth}>КУПИТЬ БИЛЕТ</button>
        </a>
      </div>
    </>
  );
};

export default EventCard;
