import * as React from 'react';
import { Menu } from '@mui/material';
import Calendar from 'react-calendar';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { getDateMain } from '../../../features/api/getDateTime';
import { getTime, getFinalTime } from '../../../features/api/getTime';
import 'react-calendar/dist/Calendar.css';


export default function UpdateDateTime({ dateDate, dateTime, dateLastTime }) {
  const [date, setDate] = React.useState(new Date());
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [certainTime, setCertainTime] = React.useState("");
  const [isFinalTime, setisFinalTime] = React.useState("");
  const [isDate, setIsDate] = React.useState("");

  const dispatch = useDispatch();

  const onChange = (newDate) => {
    setDate(newDate);
    setIsDate(new Date(newDate).toLocaleString("ru", optionsDate));
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(getDateMain(`${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`));
    dispatch(getTime(`${certainTime}`));
    dispatch(getFinalTime(`${isFinalTime}`));
  };

  let fixedTime = "";

  if (certainTime) {
    fixedTime = ` ${certainTime}-${isFinalTime}`;
  };

  const optionsDate = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  React.useEffect(() => {
    setIsDate(dateDate);
    setCertainTime(dateTime);
    setisFinalTime(dateLastTime);
    dispatch(getDateMain(dateDate));
    dispatch(getTime(dateTime));
    dispatch(getFinalTime(dateLastTime));
  }, [dateDate, dateLastTime])

  return (
    <div className="add__calendar__date">
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            disabled
            id="outlined-basic"
            label="Дата и время"
            variant="outlined"
            value={`${isDate}${fixedTime}`}
          />

          <Button variant="outlined" onClick={handleClick}>
            <EditIcon />
          </Button>

          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <Calendar
              value={date}
              onChange={onChange}
              minDate={new Date()}
              minDetail={"year"}
              navigationAriaLabel={"Go up"}
              activeStartDate={null}
            />
            <div className="time__block"
              style={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <TextField
                type="time"
                sx={{ width: 175 }}
                value={certainTime}
                onChange={(e) => {
                  setCertainTime(e.target.value)
                }}
                name="time"
                variant="outlined"
                placeholder="Время"
              />
              <TextField
                type="time"
                sx={{ width: 175 }}
                value={isFinalTime}
                onChange={(e) => {
                  setisFinalTime(e.target.value)
                }}
                name="time"
                variant="outlined"
                placeholder="Время"
              />
            </div>
            <Button variant="text" onClick={handleClose}>Добавить</Button>
          </Menu>
        </div>
      </Box>
    </div>
  );
}

