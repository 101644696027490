import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper'
import axios from 'axios';
import inMemoryJWT from '../service/inMemoryJWT';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "",
    color: theme.palette.common.black,
    fontSize: 20
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function AdminUpdate() {
  const [isEvent, setIsEvent] = React.useState([]);
  const urlApi = process.env.REACT_APP_API_URL;
  const fetchEvent = async () => {
    try {
      const response = await axios.get(`${urlApi}/api/getEvent/`);
      setIsEvent(response.data)
    } catch (e) {
      console.log(e);
    };
  };


  const ResourceClient = axios.create({
    baseURL: `${urlApi}`,
  });

  ResourceClient.interceptors.request.use(
    (config) => {
      const accessToken = inMemoryJWT.getToken();
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  const handledelete = async (e) => {
    const formData = new FormData();
    formData.append('id', e);
    try {
      await ResourceClient.post(`${urlApi}/api/deleteEvent/`, formData)
      fetchEvent();
    } catch (e) {
      console.log(e)
    };
  };

  const options = {
    month: 'long',
    day: 'numeric',
    weekday: 'long',
  };

  const optionsTime = {
    hour: 'numeric',
    minute: 'numeric',
  };


  React.useEffect(() => {
    fetchEvent();
  }, [])

  return (
    <>
      <Box sx={{ width: '100%', marginTop: 1, marginBottom: 1, marginLeft: 1.3 }}>
        <Stack spacing={2} direction="row">
          <Button variant="outlined" component={Link} to="/Admin">Добавить событие</Button>
          <Button variant="contained" component={Link} to="/Update">Редактировать события</Button>
        </Stack>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Город</StyledTableCell>
              <StyledTableCell align="right">Меcто проведения</StyledTableCell>
              <StyledTableCell align="right" sx={{ width: 760 }}>Название мероприятия</StyledTableCell>

              <StyledTableCell align="right">Исполнитель</StyledTableCell>
              <StyledTableCell align="right">Дата/День недели</StyledTableCell>
              <StyledTableCell align="right">Время</StyledTableCell>
              <StyledTableCell align="right">URL</StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isEvent.map((row, key) => (
              <StyledTableRow key={key}>
                <StyledTableCell component="th" scope="row">{row.location.city.name}</StyledTableCell>
                <StyledTableCell align="right">{row.location.place}</StyledTableCell>
                <StyledTableCell align="right">{row.name}</StyledTableCell>
                <StyledTableCell align="right">{row.artist.name}</StyledTableCell>
                <StyledTableCell align="right">{new Date(row.dateTime).toLocaleString("ru", options).toUpperCase()}</StyledTableCell>
                <StyledTableCell align="right">{`${new Date(row.dateTime).toLocaleString("ru", optionsTime).toUpperCase()} - ${row.duration}`}</StyledTableCell>
                <StyledTableCell align="right">{row.urlLending}</StyledTableCell>

                <StyledTableCell align="right">

                  <Stack direction="row" spacing={2}>
                    <Button variant="outlined" component={Link} to={row.urlLending}>
                      <EditIcon />
                    </Button>
                    <Button variant="outlined" href="#contained-buttons" color='error' onClick={() => { handledelete(row._id) }}>
                      <ClearIcon />
                    </Button>
                  </Stack>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
