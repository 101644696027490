import { createSlice } from '@reduxjs/toolkit';

export const getTimeSlice = createSlice({
  name: 'time',
  initialState: {
    value: "",
    finalTime: "",
  },
  reducers: {
    getTime: (state, action) => {
      state.value = action.payload;
    },
    getFinalTime: (state, action) => {
      state.finalTime = action.payload;
    },
  },
});

export const {getTime, getFinalTime} = getTimeSlice.actions;
export default getTimeSlice.reducer;