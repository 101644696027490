import React from "react";
import { Helmet } from "react-helmet";

import "../scss/App.scss";
import Logo from "../assets/FireShow/logoPtitsa.png";
import BackgroundImage from "../assets/FireShow/lendingBackgroundFire.jpg";
import gps from '../assets/icons/gps.png';
import ticket from '../assets/icons/ticket.png';
import time from '../assets/icons/time.png';

import BuyButton from "../components/BuyButton/BuyButton";

import img1 from "../assets/FireShow/images/img1.jpg";
import img2 from "../assets/FireShow/images/img2.jpg";
import img3 from "../assets/FireShow/images/img3.jpg";
import img4 from "../assets/FireShow/images/img4.jpg";
import img5 from "../assets/FireShow/images/img5.jpg";
import img6 from "../assets/FireShow/images/img6.jpg";

import { color, motion } from "framer-motion";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";

import { useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { incrementByAmount } from "../features/city/cityChoicer";

// настройки анимации элементов
const Animation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
};

function FireBird() {
  const dispatch = useDispatch();
  dispatch(incrementByAmount("Курск"));
  // const [eventData, setEventData] = React.useState(false); // Создаем переменную состояния "eventData" и функцию для ее обновления "setEventData"
  // const { id } = useParams(); // Получаем параметр "id" из URL

  return (
    <>
      <Helmet>
        <title>Купить билеты на музыкальное шоу «ЖАР-ПТИЦА»</title>
        <meta
          name="description"
          content="Купить билеты на концерты Курска! Уникальное музыкальное шоу «ЖАР-ПТИЦА» 5 марта 2024.
          Удобные схемы залов, электронные билеты. Легко найти интересующий вас концерт, выбрать место в зале и оформить заказ всего в несколько кликов.
          Наша платформа обеспечивает удобный и безопасный способ покупки билетов онлайн. Электронные билеты cразу после оплаты.
          Губернатроский камерный оркестра Курской области и Фольклорный ансамбль «Полдень»"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Купить билеты на музыкальное шоу «ЖАР-ПТИЦА»"></meta>
        <meta property="og:url" content="https://билетов.рус/FireBird" />
        <meta property="og:description" content="На стыке трёх стилей – фолка, рока и классики рождается уникальное музыкальное шоу «ЖАР ПТИЦА». Завораживающая музыка находит отклик в глубине души и сквозь время уносит в мир древних песен нашего народа." />
        <meta property="og:image" content={`https://билетов.рус${BackgroundImage}`}></meta>
      </Helmet>
      <Header /> {/* Компонент Header */}
      <div className="main">
        <img
          className="background__image"
          src={BackgroundImage}
          alt="Фоновая картинка мероприятия"
        />
        <img className="main__logo" src={Logo} alt="Логотип мероприятия" />
        {/* <BuyButton
          link={"https://xn--90acilpt1a.xn--p1acf/wp/tc-events/firebird/"}
        />{" "} */}
        {/* Компонент BuyButton */}
        <motion.section
          initial="hidden"
          whileInView="visible"
          className="textblock"
        >
          <motion.p
            variants={Animation}
            custom={1}
            className="textblock__title"
          >
           <br /> Губернаторский камерный оркестр Курской области. <br />
           <span className="textblock__desc">
            Дирижер - Сергей Проскурин
           </span>
            <br /><br />
            
            Фольклорный ансамбль «Полдень» <br />
            <span className="textblock__desc">рук. Анастасия Иваненкова.</span>
            <br />
            <br />
            представляют:
            <br /><br />
            <span className="textblock__desc">
             Уникальное музыкальное шоу
              </span> <br /> 
              <span className="textTitle">
              «ЖАР-ПТИЦА»
                </span>
            <br />
          </motion.p>
          <motion.p variants={Animation} custom={1} className="textblock__desc">
            Благодаря фольклорным экспедициям студентов и преподавателей
            Свиридовского колледжа были собраны народные песни Курской области.
            В которых воспевается сама жизнь, традиции и устои нашего народа.{" "}
            <br /><br />
            Затем, не меняя слов и мелодий были созданы уникальные аранжировки
            для симфонического оркестра, рок инструментов и фольклорного
            ансамбля.
            <br />
            <br />
            И вот, на стыке трёх стилей – фолка, рока и классики рождается
            уникальное музыкальное шоу «ЖАР ПТИЦА». Завораживающая музыка
            находит отклик в глубине души и сквозь время уносит в мир древних
            песен нашего народа.
            <br /><br />
            Музыкальное шоу «Жар-птица» подарит незабываемые эмоции и полёт
            души!
            </motion.p>
        </motion.section>
        <motion.section
          initial="hidden"
          whileInView="visible"
          className="iconblock"
        >
          <motion.div
            variants={Animation}
            custom={1}
            className="iconblock__item"
          >
            <img className="iconblock__item__img" src={gps} />
            <h3 className="iconblock__item__title">КЗ «СВИРИДОВСКИЙ»</h3>
            <p className="iconblock__item__p">ул. Сонина, 4</p>
          </motion.div>
          <motion.div
            variants={Animation}
            custom={2}
            className="iconblock__item"
          >
            <img className="iconblock__item__img" src={time} />
            <h3 className="iconblock__item__title">5 МАРТА</h3>
            <p className="iconblock__item__p">19:00 / 20:30</p>
          </motion.div>
          <motion.div
            variants={Animation}
            custom={3}
            className="iconblock__item"
          >
            <img className="iconblock__item__img" src={ticket} />
            <h3 className="iconblock__item__title">ЦЕНА БИЛЕТА</h3>
            <p className="iconblock__item__p">1000-2000 ₽</p>
          </motion.div>
        </motion.section>
        <BuyButton
          link={"https://xn--90acilpt1a.xn--p1acf/wp/tc-events/firebird/"}
        />
        <br />
          <span className="concertProg">
              <span className="concertProgName">
                Концертная программа
                <p className="vozrast">Возрастной рейтинг: <span className="vozrast6">6+</span></p>
              </span>
          </span>
          <motion.section           
          initial="hidden"
          whileInView="visible"
          className="textblock">
          <motion.p
              variants={Animation}
              custom={1}
              className="textblock__desc"
            >
              <span className="concertProg">Весна:</span>
              <br />
              1. Интерлюдия весна<br />
              2. “Галочка-ключница” – весенняя<br />
              закличка Железногорского р-на Курской обл.<br />
              3. “Я по садику хаживала” – хороводная песня Тимского р-на, Курской обл.<br />
              4. Трава моя ковыля – таночная песня Беловского р-на<br /><br />
              <span className="concertProg">Лето:</span><br />
              5. Интерлюдия лето – оркестровая музыка<br />
              6. “Ой, куры, куры”-плясовая Большесолдатского р-на Курской обл.<br />
              7. “Селезенюшка” – покосная песня Суджанский р-на, Курской обл.<br />
              8. “А, улица, улица” – таночная Тимский р-н Курской обл.<br /><br />
              <span className="concertProg">Осень:</span><br />
              9. Интерлюдия осень – оркестровая музыка<br />
              10. “На крыльцу молода стояла” – постовой танок Железногорский р-н Курской обл<br />
              11. “Галымба” – крыловая некрасовских казаков<br />
              12. “Какт повадилась Параня” – плясовая Белгородская обл. (Бывшая Курская)<br /><br />
              <span className="concertProg">Зима:</span><br />
              13. Интерлюдия зима – оркестровая музыка<br />
              14. “Да приехал миленький с поля” – лирическая песня Суджанский р-н<br />
              15. “Тараторки” – припевки Обоянский р-н, Курская обл.<br />
              16. “Коляда”<br />
              17. Постлюдия
          </motion.p>
          </motion.section>
        <BuyButton
          link={"https://xn--90acilpt1a.xn--p1acf/wp/tc-events/firebird/"}
        />
<iframe className="video" src="https://www.youtube.com/embed/9mAKLmcaKyw?si=tk7eZ5CW4PH1X-fS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <motion.section
          initial="hidden"
          whileInView="visible"
          class="grid-container"
        >
          <motion.img
            variants={Animation}
            custom={1.5}
            class="grid-item"
            src={img1}
          />
          <motion.img
            variants={Animation}
            custom={1.5}
            class="grid-item"
            src={img2}
          />
          <motion.img
            variants={Animation}
            custom={1.5}
            class="grid-item"
            src={img3}
          />
          <motion.img
            variants={Animation}
            custom={1.5}
            class="grid-item"
            src={img4}
          />
          <motion.img
            variants={Animation}
            custom={1.5}
            class="grid-item"
            src={img5}
          />
          <motion.img
            variants={Animation}
            custom={1.5}
            class="grid-item"
            src={img6}
          />
        </motion.section>
        <BuyButton link="https://xn--90acilpt1a.xn--p1acf/wp/tc-events/firebird/" />{" "}
        {/* Компонент BuyButton */}
        <p className="footerText">
          Организатор мероприятия: НБУКО "ЦЕНТР МУЗЫКАЛЬНОЙ КУЛЬТУРЫ И
          ИСКУССТВА" ИНН: 4632276427
        </p>
      </div>
      <Footer /> {/* Компонент Footer */}
    </>
  );
  // jsx для динамической подгрузки
  // return (
  //   <>
  //     <Header /> {/* Компонент Header */}
  //     {eventData ? (
  //       <>
  //         <div className="main">
  //           <img
  //             className="background__image"
  //             src={`http://localhost:1337${eventData.attributes.Background.data.attributes.formats.large.url}`}
  //             alt="Фоновая картинка мероприятия"
  //           />
  //           <img
  //             className="main__logo"
  //             src={`http://localhost:1337${eventData.attributes.Logo.data.attributes.url}`}
  //             alt="Логотип мероприятия"
  //           />
  //           <p>ВОЗРАСТНОЙ РЕЙТИНГ: {eventData.attributes.age_rating.data.attributes.Name}</p>
  //           <BuyButton link={eventData.attributes.BuyLink} /> {/* Компонент BuyButton */}
  //           <motion.section initial="hidden" whileInView="visible" className="textblock">
  //             <motion.p variants={Animation} custom={1} className="textblock__title">
  //               {eventData.attributes.Description}
  //             </motion.p>
  //           </motion.section>
  //           <motion.section initial="hidden" whileInView="visible" className="iconblock">
  //             <motion.div variants={Animation} custom={1} className="iconblock__item">
  //               <img className="iconblock__item__img" src={gps} />
  //               <h3 className="iconblock__item__title">
  //                 {eventData.attributes.location.data.attributes.hallName}
  //               </h3>
  //               <p className="iconblock__item__p">
  //                 {eventData.attributes.location.data.attributes.Location}
  //               </p>
  //             </motion.div>
  //             <motion.div variants={Animation} custom={2} className="iconblock__item">
  //               <img className="iconblock__item__img" src={time} />
  //               <h3 className="iconblock__item__title">{eventData.attributes.Date}</h3>
  //               <p className="iconblock__item__p">
  //                 {eventData.attributes.StartTime} / {eventData.attributes.EndTime}
  //               </p>
  //             </motion.div>
  //             <motion.div variants={Animation} custom={3} className="iconblock__item">
  //               <img className="iconblock__item__img" src={ticket} />
  //               <h3 className="iconblock__item__title">ЦЕНА БИЛЕТА</h3>
  //               <p className="iconblock__item__p">{eventData.attributes.Price}</p>
  //             </motion.div>
  //           </motion.section>
  //           <BuyButton link={eventData.attributes.BuyLink} /> {/* Компонент BuyButton */}
  //           <motion.section initial="hidden" whileInView="visible" class="grid-container">
  //             {eventData.attributes.Images.data.map((image) => (
  //               <motion.img
  //                 variants={Animation}
  //                 custom={1.5}
  //                 class="grid-item"
  //                 alt={image.attributes.name}
  //                 src={`http://localhost:1337${image.attributes.url}`}
  //               />
  //             ))}
  //           </motion.section>
  //           <BuyButton link={eventData.attributes.BuyLink} /> {/* Компонент BuyButton */}
  //           <p className="footerText">
  //             Организатор мероприятия:
  //             {eventData.attributes.organizer_detail.data.attributes.Value}
  //             {eventData.attributes.organizer_detail.data.attributes.INN}
  //           </p>
  //         </div>
  //         <Footer /> {/* Компонент Footer */}
  //       </>
  //     ) : (
  //       <></>
  //     )}
  //   </>
  // );
}

export default FireBird;
