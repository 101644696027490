import { createSlice } from '@reduxjs/toolkit';

export const colorSlice = createSlice({
  name: 'colorBtn',
  initialState: {
    colorBkg: "",
    colorColor: "",
  },
  reducers: {
    colorBackground: (state, action) => {
      state.colorBkg = action.payload;
    },
    colorColor: (state, action) => {
      state.colorColor = action.payload;
    }
  },
});

export const {colorBackground} = colorSlice.actions;
export const {colorColor} = colorSlice.actions;
export default colorSlice.reducer;