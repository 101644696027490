import React, { useState } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { Menu, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getAllOrganizer, getOrganizerId } from '../../features/api/organizerApi';
import axios from 'axios';
import inMemoryJWT from '../../service/inMemoryJWT';

export default function AddOrganizator({ organizer }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tempId, setTempId] = React.useState('');
  const ariaLabel = { 'aria-label': 'description' };
  const allOrganizers = useSelector((state) => state.organizerApi.value);
  const dispatch = useDispatch();
  const urlApi = process.env.REACT_APP_API_URL;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [formData, setFormData] = useState({
    name: "",
    inn: "",
    ogrnip: "",
    adress: ""
  });

  const handleInputChangePost = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const ResourceClient = axios.create({
    baseURL: `${urlApi}`,
  });

  ResourceClient.interceptors.request.use(
    (config) => {
      const accessToken = inMemoryJWT.getToken();
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await ResourceClient.post(`${urlApi}/api/setOrganizer/`, formData);
    } catch (error) {
      console.log('Error: ', error);
    };
  };

  const fetchOrganizers = async () => {
    try {
      const response = await axios.get(`${urlApi}/api/getOrganizer/`);
      dispatch(getAllOrganizer(response.data));
    } catch (error) {
      console.log("Ошибка получения данных: ", error);
    };
  };

  React.useEffect(() => {
    setTempId(organizer);
    dispatch(getOrganizerId(organizer));
  }, [organizer]);

  return (
    <div className="add__organizator">
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch', bgcolor: "white" },
        }}
        noValidate
        autoComplete="off"
      >
        {/* Организатор */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            id="outlined-select-currency-native"
            select
            value={tempId || ''}
            label="Организатор"
            type='text'
            onChange={(e) => {
              setTempId(e.target.value);
              dispatch(getOrganizerId(e.target.value))
            }}
            onMouseDown={() => { fetchOrganizers() }}
          >
            {allOrganizers.map((organizer) => (
              <MenuItem key={organizer._id} value={organizer._id}>
                {organizer.name}
              </MenuItem> 
            ))}
          </TextField>

          {/* Button */}
          <Button variant="outlined" onClick={handleClick}>
            <AddIcon />
          </Button>

          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <form onSubmit={handleSubmit}>
              <label>
                <TextField
                  value={formData.name}
                  name="name"
                  onChange={handleInputChangePost}
                  placeholder="Название"
                  inputProps={ariaLabel}
                />
                <TextField
                  value={formData.inn}
                  name="inn"
                  onChange={handleInputChangePost}
                  placeholder="ИНН"
                  inputProps={ariaLabel}
                />
                <TextField
                  value={formData.ogrnip}
                  name="ogrnip"
                  onChange={handleInputChangePost}
                  placeholder="ОГРНИП"
                  inputProps={ariaLabel}
                />
                <TextField
                  value={formData.adress}
                  name="adress"
                  onChange={handleInputChangePost}
                  placeholder="Юридический адрес"
                  inputProps={ariaLabel}
                />
              </label>
              <Button type="submit" variant="text">Добавить</Button>
            </form>
          </Menu>
        </div>
      </Box>
    </div>
  )
}