import { createSlice } from '@reduxjs/toolkit';

export const getDateSlice = createSlice({
  name: 'date',
  initialState: {
    value: "",
  },
  reducers: {
    getDateMain: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const {getDateMain} = getDateSlice.actions;
export default getDateSlice.reducer;