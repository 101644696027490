import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { descriptionLink } from '../../features/api/SeoLinks';

export default function AddDescriptionSeo({ seoDescription }) {
  const [description, setDescription] = React.useState("");
  const dispatch = useDispatch();


  React.useEffect(() => {
    setDescription(seoDescription);
    dispatch(descriptionLink(seoDescription))
  }, [seoDescription])

  return (
    <div className="add__link">
      <Box
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch', bgcolor: "white" },
        }}
        noValidate
        autoComplete="off"
      >
        {/* Ссылка */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            id="outlined-basic"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              dispatch(descriptionLink(e.target.value))
            }}
            label="Описание Seo"
            variant="outlined"
            
          />
        </div>
      </Box>
    </div>
  )
}