import React, { useState } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import { Menu, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getAllLocation } from '../../../features/api/locationApi';
import { idLocation } from '../../../features/api/locationApi'
import axios from 'axios';

export default function AddCity({ locationId, locationIdMain }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tempId, setTempId] = React.useState('');
  const [isLocation, setLocation] = React.useState('');
  const allLocations = useSelector((state) => state.locationApi.value);
  const getCityidMain = useSelector((state) => state.getCityid.value);
  const dispatch = useDispatch();
  const urlApi = process.env.REACT_APP_API_URL;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const config = {
    city: getCityidMain
  };

  const getSelectedLocationName = () => {
    const selectedLocation = allLocations.find((location) => location._id === tempId);
    return selectedLocation ? selectedLocation.place : '';
  };

  const fetchLocations = async () => {
    try {
      const response = await axios.post(`${urlApi}/api/getLocation/`, config);

      dispatch(getAllLocation(response.data));
    } catch (error) {
      console.log("Ошибка получения данных: ", error);
    };
  };

  React.useEffect(() => {
    setLocation(locationId);
    dispatch(idLocation(locationIdMain));
  }, [locationId]);


  return (
    <div className="add__location">
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch', bgcolor: "white" },
        }}
        noValidate
        autoComplete="off"
      >
        {/* Место проведения */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            disabled
            id="outlined-select-currency-native"
            value={tempId ? getSelectedLocationName() : isLocation}
            label="Место проведения"
          />

          {/* Button */}
          <Button variant="outlined" onClick={handleClick}>
            <EditIcon />
          </Button>

          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <TextField
              id="outlined-select-currency-native"
              select
              value={tempId || ''}
              label="Место проведения"
              onChange={(e) => {
                setTempId(e.target.value);
                dispatch(idLocation(e.target.value))
              }}
              onMouseDown={() => { fetchLocations() }}
              sx={{ width: 300 }}
            >
              {allLocations.map((location) => (
                <MenuItem key={location._id} value={location._id} onClick={handleClose}>
                  {location.place}
                </MenuItem>
              ))}
            </TextField>
          </Menu>
        </div>
      </Box>
    </div>
  )
}