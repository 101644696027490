import React from 'react';

import FooterLogo from '../../assets/biletov.png';
import styles from './Footer.module.scss';

// компонент футера
const Footer = () => {
  return (
    <div className={styles.footercontainer}>
      <footer className={styles.footer}>
        <div className={styles.footer__leftblock}>
          <a href="https://xn--90acilpt1a.xn--p1acf/wp/information/" className={styles.a__link}>
            <h3 className={styles.footer__title}>КОНТАКТНАЯ ИНФОРМАЦИЯ</h3>
          </a>
          <p className={styles.footer__description}>Телефон: +7 495 118-34-19</p>
          <p className={styles.footer__description}>Эл. почта: info@билетов.рус</p>
        </div>
        <div className={styles.footer__centerblock}>
          <h3 className={styles.footer__title}>О СЕРВИСЕ</h3>
          <p className={styles.footer__description}>
            Сервис-проект продажи/покупки билетов БИЛЕТОВ.РУС призван сделать поиск и приобретение
            билетов на различные мероприятия городов России простым и понятным для зрителя, а для
            организаторов зрелищных мероприятий стать понятной и удобной площадкой по реализации
            билетов. Разработка сайта и техническая поддержка: Цифровое 3D агентство{' '}
            <a href="https://xn--d1abb4ane.xn--p1acf/" className={styles.a__link}>
              РЕНДЕР.РУС
            </a>
          </p>
        </div>
        <div className={styles.footer__rightblock}>
          <h3 className={styles.footer__title}>ПОЛЬЗОВАТЕЛЯМ</h3>
          <a href="https://xn--90acilpt1a.xn--p1acf/wp/privacy-policy/" className={styles.a__link}>
            <p className={styles.footer__description}>Политика конфиденциальности</p>
          </a>
          <a href="https://xn--90acilpt1a.xn--p1acf/wp/purchasing-info/" className={styles.a__link}>
            <p className={styles.footer__description}>Информация о покупке</p>
          </a>
          <a href="https://xn--90acilpt1a.xn--p1acf/wp/refund/" className={styles.a__link}>
            <p className={styles.footer__description}>Возврат билета</p>
          </a>
          <a
            href="https://xn--90acilpt1a.xn--p1acf/wp/conditions-of-use/"
            className={styles.a__link}>
            <p className={styles.footer__description}>Договор оферты</p>
          </a>
          <a href="https://xn--90acilpt1a.xn--p1acf/wp/cancellations/" className={styles.a__link}>
            <p className={styles.footer__description}>Перенос / отмена мероприятий</p>
          </a>
        </div>
      </footer>
      <div className={styles.bottomfooter}>
        <img src={FooterLogo} className={styles.bottomfooter__img}></img>
        <p className={styles.bottomfooter__p}>© 2023 WWW.БИЛЕТОВ.РУС</p>
      </div>
    </div>
  );
};

export default Footer;
