import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import "./AddParticipant.css"

export default function AddParticipant() {
  const [isForm, setForm] = React.useState([]);
  const [formData, setFormData] = React.useState([{
    name: "",
    lastName: "",
    ageCategory: "",
  }]);
  const urlApi = process.env.REACT_APP_API_URL;

  const handleInputChange = (i, e) => {
    const newFormValues = [...formData];
    newFormValues[i][e.target.name] = e.target.value;
    setFormData(newFormValues);
  };

  const addFormFields = () => {
    for (let i = 0; i < formData.length; i++) {
      if (formData[i].name.trim() === ""
        || formData[i].lastName.trim() === ""
        || formData[i].ageCategory.trim() === "") {
        return;
      }
    };

    setFormData([...formData, { name: "", lastName: "", ageCategory: "" }]);
  };

  const handleDelete = (index) => {
    if (formData.length === 1) return;
    setForm([...formData]);
    formData.splice(index, 1);
  };

  const handleSubmit = async () => {
    for (let i = 0; i < formData.length; i++) {
      if (formData[i].name.trim() === ""
        || formData[i].lastName.trim() === ""
        || formData[i].ageCategory.trim() === "") {
        return;
      };
    };
    const formDataSend = new FormData();
    const quantityParam = formData.length > 1 ? `&quantity=${formData.length}` : '';
    const url = `https://билетов.рус/wp/tc-events/boycovskoe-troebore/?add-to-cart=5474${quantityParam}`;

    formDataSend.append('athlete', JSON.stringify(formData))

    try {
      await axios.post(`${urlApi}/api/hermesMail/`, formDataSend);
    } catch (e) {
      console.log(e);
    };

    window.location.href = url;
    setForm([...formData]);
  };

  return (
    <>
      <Typography
        variant="h3"
        sx={{
          mr: 2,
          fontWeight: 700,
          fontSize: 30,
          letterSpacing: '.1rem',
          marginBottom: 3,
          textAlign: "center",
          marginRight: 0,
        }}
      >
        Регистрация участника
      </Typography>

      <Container className="from__container" maxWidth="md" sx={{ display: "grid", justifyContent: "center", flexWrap: "wrap" }}>
        {formData.map((e, index) => (
          <Grid className={formData.length === 1 ? "class__test1" : "class__test2"}>
            <div className="form__registration" style={{ marginBottom: 20, display: "flex", flex: "0 0 calc(16.66% - 20px)", alignItems: "center", justifyContent: "center", }} key={index}>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '25ch', },
                  bgcolor: "#181818",
                  border: "none",
                  display: "flex",
                  flexFlow: "column",
                  borderRadius: 2,
                }}
              >
                <TextField
                  size="small"
                  value={e.name}
                  onChange={e => { handleInputChange(index, e) }}
                  name="name"
                  className="input__form"
                  label="Имя участника"
                  InputLabelProps={
                    e.name.trim() === "" ?
                      { style: { color: "#979797" } } :
                      { style: { color: "#099721" } }
                  }
                  InputProps={{ color: "none", }}
                  variant="filled"
                />
                <TextField
                  size='small'
                  value={e.lastName}
                  onChange={e => handleInputChange(index, e)}
                  name="lastName"
                  className="input__form"
                  label="Фамилия участника"
                  InputLabelProps={
                    e.lastName.trim() === "" ?
                      { style: { color: "#979797" } } :
                      { style: { color: "#099721" } }
                  }
                  InputProps={{ color: "none", }}
                  variant="filled"
                />
                <TextField
                  size="small"
                  type="number"
                  value={e.ageCategory}
                  onChange={e => handleInputChange(index, e)}
                  name="ageCategory"
                  className="input__form"
                  label="Возраст участника"
                  InputLabelProps={
                    e.ageCategory.trim() === "" ?
                      { style: { color: "#979797" } } :
                      { style: { color: "#099721" } }
                  }
                  InputProps={{ color: "none", }}
                  variant="filled"
                />
              </Box>
              <div className="btn__block" style={{position: "absolute"}}>
                {formData.length === 1 ?
                  <button className="btn__delete_off">
                    <HighlightOffIcon />
                  </button>
                  :
                  <button type="submit" className="btn__delete" onClick={() => handleDelete(index)}>
                    <HighlightOffIcon />
                  </button>
                }
              </div>

            </div>
          </Grid>
        ))}
      </Container>
      <div className="add__button" style={{ display: "flex", alignItems: "center" }} onClick={addFormFields}>
        <Fab
          color="primary"
          sx={{ backgroundColor: "rgba(0,0,0,0)", "&:hover": { backgroundColor: "transparent" } }}
          aria-label="add"
          size="small">
          <AddIcon />
        </Fab>
        <Typography
          variant="a"
          sx={{
            mr: 2,
            fontWeight: 700,
            fontSize: 20,
            letterSpacing: '.1rem',
            textDecoration: 'none',
          }}
        >
          Добавить ещё участника
        </Typography>
      </div>

      <div>
        <button className="btn__buy" style={{ marginTop: 10, marginBottom: 10 }} onClick={handleSubmit}>
          {formData.length === 1 ? "Оплатить слот" : "Оплатить слоты"}
        </button>
      </div>

      <div style={{ display: "flex", marginBottom: 20, }} >
        <Typography
          variant="p"
          sx={{
            mr: 2,
            fontSize: 12,
            letterSpacing: '.1rem',
            textAlign: "center",
            margin: 0,
          }}
        >
          Нажимая {formData.length === 1 ? `"Оплатить слот"` : `"Оплатить слоты"`}, вы соглашаетесь с <a style={{ color: "#ffffff" }} href="https://xn--90acilpt1a.xn--p1acf/wp/conditions-of-use/">офертой</a> и <a style={{ color: "#ffffff" }} href="https://xn--90acilpt1a.xn--p1acf/wp/privacy-policy/">политикой конфиденциальности</a>.
        </Typography>
      </div>
    </>
  )
}
