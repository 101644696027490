import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import { Menu, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getTypeEvent } from '../../../features/api/typeEventApi';
import { idTypeEvent } from '../../../features/api/typeEventApi';
import axios from 'axios';

export default function UpdateTypeEvent({ typeEvent, typeEventId, multi }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tempId, setTempId] = React.useState('');
  const [isTypeEvent, setTypeEvent] = React.useState('')
  const AllTypeEvent = useSelector((state) => state.getTypeEvent.value);
  const dispatch = useDispatch();
  const urlApi = process.env.REACT_APP_API_URL;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getSelectedTypeEvent = () => {
    const selectedTypeEvent = AllTypeEvent.find((typeEvent) => typeEvent._id === tempId);
    return selectedTypeEvent ? selectedTypeEvent.name : '';
  };

  // Get
  const fetchTypesEvents = async () => {
    try {
      const response = await axios.get(`${urlApi}/api/getTypeEvent/`);
      dispatch(getTypeEvent(response.data));
    } catch (error) {
      console.log("Ошибка получения данных: ", error);
    };
  };

  useEffect(() => {
    setTypeEvent(typeEvent.name)
    dispatch(idTypeEvent(typeEventId))
  }, [typeEvent])

  return (
    <div className="add__type__event">
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch', bgcolor: "white" },
        }}
        noValidate
        autoComplete="off"
      >
        {/* Тип мероприятия */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            id="outlined-select-currency-native"
            value={tempId ? getSelectedTypeEvent() : isTypeEvent}
            label="Типы мероприятия"
            type='text'
            disabled
          />

          {/* Button */}
          <Button variant="outlined" onClick={handleClick}>
            <EditIcon />
          </Button>

          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <TextField
              id="outlined-select-currency-native"
              select
              value={tempId || ''}
              label="Типы мероприятия"
              type='text'
              onChange={(e) => {
                setTempId(e.target.value);
                dispatch(idTypeEvent(e.target.value))
              }}
              onMouseDown={() => fetchTypesEvents()}
              sx={{ width: 300 }}
            >
              {AllTypeEvent.map((event) => (
                <MenuItem key={event._id} value={event._id} onClick={handleClose}>
                  {event.name}
                </MenuItem>
              ))}
            </TextField>
          </Menu>
        </div>
      </Box>
    </div>
  )
}