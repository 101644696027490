import { createSlice } from '@reduxjs/toolkit';

export const getNameSlice = createSlice({
  name: 'name',
  initialState: {
    value: "",
    clearName: "",
  },
  reducers: {
    getName: (state, action) => {
      state.value = action.payload;
    },
    clearNameMain: (state, action) => {
      state.clearName = action.payload;
    }
  },
});

export const {getName} = getNameSlice.actions;
export const {clearNameMain} = getNameSlice.actions;
export default getNameSlice.reducer;