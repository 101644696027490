import React from 'react';
import { Helmet } from 'react-helmet';
import '../scss/App.scss';
import Logo from '../assets/Orchestral/logo.png';
import BackgroundImage from '../assets/Orchestral/bg.jpg';
import gps from '../assets/icons/gps.png';
import ticket from '../assets/icons/ticket.png';
import time from '../assets/icons/time.png';

import BuyButton from '../components/BuyButton/BuyButton';

import img1 from '../assets/Orchestral/images/1.jpg';
import img2 from '../assets/Orchestral/images/2.jpg';
import img3 from '../assets/Orchestral/images/3.jpg';
import img4 from '../assets/Orchestral/images/4.jpg';
import img5 from '../assets/Orchestral/images/5.jpg';
import img6 from '../assets/Orchestral/images/6.jpg';

import { motion } from 'framer-motion';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Form from '../components/Form/Form';

import { useSelector, useDispatch } from "react-redux";
import { incrementByAmount } from "../features/city/cityChoicer";

// настройки анимации элементов
const Animation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
};

function Orchestral() {
  const dispatch = useDispatch();
  dispatch(incrementByAmount('Курск'));
  return (
    <>
      <Helmet>
        <title>Купить билеты на музыкальное шоу «Оркестровые шедевры»</title>
        <meta
          name="description"
          content="Купить билеты на концерты Курска! Уникальное музыкальное шоу «Оркестровые шедевра» 20 марта 2024.
          Удобные схемы залов, электронные билеты. Легко найти интересующий вас концерт, выбрать место в зале и оформить заказ всего в несколько кликов.
          Наша платформа обеспечивает удобный и безопасный способ покупки билетов онлайн. Электронные билеты cразу после оплаты.
          Губернатроский камерный оркестра Курской области"
        />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Купить билеты на музыкальное шоу «Оркестровые шедевры»"></meta>
        <meta property="og:url" content="https://билетов.рус/Orchestral" />
        <meta property="og:description" content=" «Оркестровые шедевры» - это удивительное путешествие в мир классической музыки под руководством дирижера, маэстро Сергея Проскурина. В концерте прозвучат незабываемые, проверенные веками музыкальные шедевры великих композиторов.  Концерт позволит вам перенесетесь в мир Италии 16-го века, в музыкальную Вену 18-го века, а также погрузиться в золотой век русской культуры, который для вас откроет П.И. Чайковский." />
        <meta property="og:image" content={`https://билетов.рус${BackgroundImage}`}></meta>
      </Helmet>
      <Header /> {/* Компонент Header */}
      <div className="main">
        <img
          className="background__image"
          src={BackgroundImage}
          alt="Фоновая картинка мероприятия"
        />
        <img className="main__logo" src={Logo} alt="Логотип мероприятия" />
        {/* Компонент BuyButton */}
        <motion.section initial="hidden" whileInView="visible" className="textblock">
          <motion.p variants={Animation} custom={1} className="textblock__title">
            «Оркестровые шедевры»
          </motion.p>
          <motion.p variants={Animation} custom={1} className="textblock__desc">
            «Оркестровые шедевры» - это удивительное путешествие в мир классической музыки под
            руководством дирижера, маэстро Сергея Проскурина. <br /> <br />
            В концерте прозвучат незабываемые, проверенные веками музыкальные шедевры великих композиторов. <br /> <br />
            Концерт позволит вам перенесетесь в мир Италии 16-го века, в музыкальную Вену 18-го века, а также погрузиться
            в золотой век русской культуры, который для вас откроет П.И. Чайковский.
          </motion.p>
        </motion.section>
        <motion.section initial="hidden" whileInView="visible" className="iconblock">
          <motion.div variants={Animation} custom={1} className="iconblock__item">
            <img className="iconblock__item__img" src={gps} />
            <h3 className="iconblock__item__title">КЗ «СВИРИДОВСКИЙ»</h3>
            <p className="iconblock__item__p">ул. Сонина, 4</p>
          </motion.div>
          <motion.div variants={Animation} custom={2} className="iconblock__item">
            <img className="iconblock__item__img" src={time} />
            <h3 className="iconblock__item__title">20 МАРТА</h3>
            <p className="iconblock__item__p">19:00 / 20:20</p>
          </motion.div>
          <motion.div variants={Animation} custom={3} className="iconblock__item">
            <img className="iconblock__item__img" src={ticket} />
            <h3 className="iconblock__item__title">ЦЕНА БИЛЕТА</h3>
            <p className="iconblock__item__p">500-1200 ₽</p>
          </motion.div>
        </motion.section>
        <BuyButton
          link={'https://xn--90acilpt1a.xn--p1acf/wp/tc-events/orchestral-masterpieces/'}
        />
          <span className="concertProg">
              <span className="concertProgName">
                <br /> <br />Концертная программа
                <p className="vozrast">Возрастной рейтинг: <span className="vozrast6">6+</span></p>
              </span>
          </span>
        <motion.section initial="hidden" whileInView="visible" className="textblock">
        <motion.p variants={Animation} custom={1} className="textblock__desc">
        А. Вивальди – «Зима» из цикла «Времена года» <br />
        В.А. Моцарт – Дивертисмент №3. KМ 138.<br />
        П.И. Чайковский – «Февраль», «Апрель», «Июнь» , «Октябрь» из цикла «Времена года»<br />
        </motion.p>
        </motion.section>


        <motion.section initial="hidden" whileInView="visible" class="grid-container">
          <motion.img variants={Animation} custom={1.5} class="grid-item" src={img1} />
          <motion.img variants={Animation} custom={1.5} class="grid-item" src={img2} />
          <motion.img variants={Animation} custom={1.5} class="grid-item" src={img3} />
          <motion.img variants={Animation} custom={1.5} class="grid-item" src={img4} />
          <motion.img variants={Animation} custom={1.5} class="grid-item" src={img5} />
          <motion.img variants={Animation} custom={1.5} class="grid-item" src={img6} />
        </motion.section>
        <BuyButton link="https://xn--90acilpt1a.xn--p1acf/wp/tc-events/orchestral-masterpieces/" />
        {/* Компонент BuyButton */}
        <p className="footerText">
          Организатор мероприятия: <br /> НБУКО "ЦЕНТР МУЗЫКАЛЬНОЙ КУЛЬТУРЫ И ИСКУССТВА" <br /> ИНН:
          4632276427
        </p>
      </div>
      <Footer /> {/* Компонент Footer */}
    </>
  );
  // jsx для динамической подгрузки
  // return (
  //   <>
  //     <Header /> {/* Компонент Header */}
  //     {eventData ? (
  //       <>
  //         <div className="main">
  //           <img
  //             className="background__image"
  //             src={`http://localhost:1337${eventData.attributes.Background.data.attributes.formats.large.url}`}
  //             alt="Фоновая картинка мероприятия"
  //           />
  //           <img
  //             className="main__logo"
  //             src={`http://localhost:1337${eventData.attributes.Logo.data.attributes.url}`}
  //             alt="Логотип мероприятия"
  //           />
  //           <p>ВОЗРАСТНОЙ РЕЙТИНГ: {eventData.attributes.age_rating.data.attributes.Name}</p>
  //           <BuyButton link={eventData.attributes.BuyLink} /> {/* Компонент BuyButton */}
  //           <motion.section initial="hidden" whileInView="visible" className="textblock">
  //             <motion.p variants={Animation} custom={1} className="textblock__title">
  //               {eventData.attributes.Description}
  //             </motion.p>
  //           </motion.section>
  //           <motion.section initial="hidden" whileInView="visible" className="iconblock">
  //             <motion.div variants={Animation} custom={1} className="iconblock__item">
  //               <img className="iconblock__item__img" src={gps} />
  //               <h3 className="iconblock__item__title">
  //                 {eventData.attributes.location.data.attributes.hallName}
  //               </h3>
  //               <p className="iconblock__item__p">
  //                 {eventData.attributes.location.data.attributes.Location}
  //               </p>
  //             </motion.div>
  //             <motion.div variants={Animation} custom={2} className="iconblock__item">
  //               <img className="iconblock__item__img" src={time} />
  //               <h3 className="iconblock__item__title">{eventData.attributes.Date}</h3>
  //               <p className="iconblock__item__p">
  //                 {eventData.attributes.StartTime} / {eventData.attributes.EndTime}
  //               </p>
  //             </motion.div>
  //             <motion.div variants={Animation} custom={3} className="iconblock__item">
  //               <img className="iconblock__item__img" src={ticket} />
  //               <h3 className="iconblock__item__title">ЦЕНА БИЛЕТА</h3>
  //               <p className="iconblock__item__p">{eventData.attributes.Price}</p>
  //             </motion.div>
  //           </motion.section>
  //           <BuyButton link={eventData.attributes.BuyLink} /> {/* Компонент BuyButton */}
  //           <motion.section initial="hidden" whileInView="visible" class="grid-container">
  //             {eventData.attributes.Images.data.map((image) => (
  //               <motion.img
  //                 variants={Animation}
  //                 custom={1.5}
  //                 class="grid-item"
  //                 alt={image.attributes.name}
  //                 src={`http://localhost:1337${image.attributes.url}`}
  //               />
  //             ))}
  //           </motion.section>
  //           <BuyButton link={eventData.attributes.BuyLink} /> {/* Компонент BuyButton */}
  //           <p className="footerText">
  //             Организатор мероприятия:
  //             {eventData.attributes.organizer_detail.data.attributes.Value}
  //             {eventData.attributes.organizer_detail.data.attributes.INN}
  //           </p>
  //         </div>
  //         <Footer /> {/* Компонент Footer */}
  //       </>
  //     ) : (
  //       <></>
  //     )}
  //   </>
  // );
}

export default Orchestral;
