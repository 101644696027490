import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import { Menu, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAge } from '../../../features/api/ageApi';
import { idAllAge } from '../../../features/api/ageApi';
import axios from 'axios';

export default function UpdateAge({ updateAge, updateAgeId }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tempId, setTempId] = React.useState('');
  const [isAge, setAge] = React.useState('');
  const allAges = useSelector((state) => state.getAge.value);
  const dispatch = useDispatch();
  const urlApi = process.env.REACT_APP_API_URL;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getSelectedUpdateAge = () => {
    const selectedUpdateAge = allAges.find((updateAge) => updateAge._id === tempId);
    return selectedUpdateAge ? selectedUpdateAge.age : '';
  };

  // get
  const fetchAges = async () => {
    try {
      const response = await axios.get(`${urlApi}/api/getRating/`);
      dispatch(getAllAge(response.data))
    } catch (error) {
      console.log("Ошибка получения данных: ", error);
    };
  };

  useEffect(() => {
    setAge(updateAge);
    dispatch(idAllAge(updateAgeId))
  }, [updateAge])

  return (
    <div className="add__age">
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch', bgcolor: "white" },
        }}
        noValidate
        autoComplete="off"
      >
        {/* Город */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            id="outlined-select-currency-native"
            value={tempId ? getSelectedUpdateAge() : isAge}
            label="Возрастной рейтинг"
            disabled
          />


          {/* Button */}
          <Button variant="outlined" onClick={handleClick}>
            <EditIcon />
          </Button>

          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <TextField
              id="outlined-select-currency-native"
              select
              value={tempId || ''}
              label="Возрастной рейтинг"
              onChange={(e) => { setTempId(e.target.value); dispatch(idAllAge(e.target.value)) }}
              onMouseDown={() => fetchAges()}
              sx={{width: 300}}
            >
              {allAges.map((age) => (
                <MenuItem key={age._id} value={age._id} onClick={handleClose}>
                  {age.age}
                </MenuItem>
              ))};
            </TextField>
          </Menu>
        </div>
      </Box>
    </div>
  )
}