import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { titleLink } from '../../features/api/SeoLinks';

export default function AddTitleSeo({ seoTitle }) {
  const [title, setTitle] = React.useState("");
  const dispatch = useDispatch();


  React.useEffect(() => {
    setTitle(seoTitle);
    dispatch(titleLink(seoTitle));
  
  }, [seoTitle]);

  return (
    <div className="add__link">
      <Box
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch', bgcolor: "white" },
        }}
        noValidate
        autoComplete="off"
      >
        {/* Ссылка */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            id="outlined-basic"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              dispatch(titleLink(e.target.value))
            }}
            label="Название Seo"
            variant="outlined"
          />
        </div>
      </Box>
    </div>
  )
}
