import { createSlice } from '@reduxjs/toolkit';

export const getSeoSlice = createSlice({
  name: 'seoLinks',
  initialState: {
    seo: {
      title: "",
      description: "",
    },
  },
  reducers: {
    titleLink: (state, action) => {
      state.seo.title = action.payload;
    },
    descriptionLink: (state, action) => {
      state.seo.description = action.payload;
    },
  },
});

export const {titleLink} = getSeoSlice.actions;
export const {descriptionLink} = getSeoSlice.actions;
export default getSeoSlice.reducer;