import { createSlice } from '@reduxjs/toolkit';

export const PathPhotoSlice = createSlice({
  name: 'savePhotoPath',
  initialState: {
    pathGallary: [],
    pathBanner: [],
    pathVideoHead: [],
    pathLogo: [],
    pathPreview: [],
    pathVideoBody: [],
  },
  reducers: {
    pathPhotoChange: (state, action) => {
      state.pathGallary = action.payload;
    },
    pathBannerChange: (state, action) => {
      state.pathBanner = action.payload;
    },
    pathVideoHeadChange:  (state, action) => {
      state.pathVideoHead = action.payload;
    },
    pathLogoChange:  (state, action) => {
      state.pathLogo = action.payload;
    },
    pathPreviewChange:  (state, action) => {
      state.pathPreview = action.payload;
    },
    pathVideoBodyChange:  (state, action) => {
      state.pathVideoBody = action.payload;
    },
  },
});

export const {pathPhotoChange, pathBannerChange, pathVideoBodyChange, pathLogoChange, pathPreviewChange, pathVideoHeadChange} = PathPhotoSlice.actions;
export default PathPhotoSlice.reducer;