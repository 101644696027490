import React, { useState } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { Menu, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getAllExecutor } from '../../features/api/executorApi';
import { idAllExecutor } from '../../features/api/executorApi';
import axios from 'axios';
import inMemoryJWT from '../../service/inMemoryJWT';

export default function AddExecutor() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tempId, setTempId] = React.useState('');
  const ariaLabel = { 'aria-label': 'description' };
  const allExecutors = useSelector((state) => state.executorApi.value);
  const dispatch = useDispatch();
  const urlApi = process.env.REACT_APP_API_URL;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [formData, setFormData] = useState({
    name: "",
    abbreviation: "",
  });

  const handleInputChangePost = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const ResourceClient = axios.create({
    baseURL: `${urlApi}`,
  });

  ResourceClient.interceptors.request.use(
    (config) => {
      const accessToken = inMemoryJWT.getToken();
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );


  // Post
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await ResourceClient.post(`${urlApi}/api/setArtist/`, formData);
    } catch (error) {
      console.log('Error: ', error);
    };
  };

  // Get
  const fetchExecutors = async () => {
    try {
      const response = await axios.get(`${urlApi}/api/getArtist/`);
      dispatch(getAllExecutor(response.data));
    } catch (error) {
      console.log("Ошибка получения данных: ", error);
    };
  };

  return (
    <div className="add__executor">
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch', bgcolor: "white" },
        }}
        noValidate
        autoComplete="off"
      >
        {/* Исполнители */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            id="outlined-select-currency-native"
            select
            value={tempId || ''}
            label="Исполнитель"
            onChange={(e) => { setTempId(e.target.value); dispatch(idAllExecutor(e.target.value)) }}
            onMouseDown={() => fetchExecutors()}
          >
            {allExecutors.map((executor) => (
              <MenuItem key={executor._id} value={executor._id}>
                {executor.name}
              </MenuItem>
            ))}
          </TextField>

          {/* Button */}
          <Button variant="outlined" onClick={handleClick}>
            <AddIcon />
          </Button>

          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <form onSubmit={handleSubmit}>
              <label>
                <TextField
                  value={formData.name}
                  name='name'
                  onChange={handleInputChangePost}
                  placeholder="Имя"
                  inputProps={ariaLabel}
                />
                <TextField
                  value={formData.abbreviation}
                  name='abbreviation'
                  onChange={handleInputChangePost}
                  placeholder="Аббривиатура"
                  inputProps={ariaLabel}
                />
              </label>
              <Button
                type="submit"
                variant="text"
                onClick={handleClose}
              >
                Добавить
              </Button>
            </form>
          </Menu>
        </div>
      </Box>
    </div>
  )
}