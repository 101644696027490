import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import BuyButton from '../BuyButton/BuyButton';
import { useDispatch, useSelector } from 'react-redux';
import { colorBackground, colorColor } from '../../features/api/colorBtn';


export default function AddColorButton({ colorBkg, colorTxt }) {
  const [color, setColor] = useState("#ffcc00");
  const [colorText, setColorText] = useState("#000000");
  const [isSend, setIsSend] = useState(false);
  const colorGet = useSelector((state) => state.colorButton.colorBkg)
  const dispatch = useDispatch();

  const handleSend = () => {
    if (color.trim() === "" || colorText.trim() === "") return;
    dispatch(colorBackground(color));
    dispatch(colorColor(colorText));
    setIsSend(true);
  };

  const handleClick = () => {
    setIsSend(false);
  };

  useEffect(() => {
    setColor(colorBkg);
    setColorText(colorTxt);
    dispatch(colorBackground(color));
    dispatch(colorColor(colorText));
  }, []);


  return (
    <>
      <Typography
        variant="a"
        sx={{
          mr: 2,
          color: "black",
          fontWeight: 700,
          fontSize: 20,
          display: "flex",
          justifyContent: "center",
          letterSpacing: '.1rem',
          textDecoration: 'none',
          marginBottom: 2,
        }}
      >
        Цветовой код кнопок
      </Typography>
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        noValidate
        autoComplete="off"
      >
        <div className="btn__color" style={{ height: 145, width: 290, marginTop: 0 }}>
          <BuyButton colorAdd={color} textColor={colorText} borderButton={"1px solid #000000"} />
        </div>

        <div className="input__color">
          <TextField
            onClick={handleClick}
            sx={{ width: 230 }}
            type="color"
            label="Цвет кнопки"
            id="outlined-basic"
            variant="outlined"
            value={color} onChange={(e) => setColor(e.target.value)}
          />
          <TextField
            onClick={handleClick}
            type="color"
            label="Цвет текста"
            id="outlined-basic"
            variant="outlined"
            value={colorText}
            onChange={(e) => setColorText(e.target.value)}
            sx={{ width: 230, marginTop: 2 }}
          />

          <div className="btn__block" style={{ display: "flex", justifyContent: "end" }}>
            {!isSend ?
              <button
                onClick={handleSend}
                className="btn__color_uncomplete"
                variant="outlined"
              >
                Сохранить
              </button>
              :
              <button
                onClick={handleSend}
                className="btn__color_complete"
                variant="outlined"
              >
                Сохранено
              </button>
            }
          </div>
        </div>
      </Box>
    </>
  )
}
