import { createSlice } from '@reduxjs/toolkit';

export const getDescriptionSlice = createSlice({
  name: 'description',
  initialState: {
    value: "",
  },
  reducers: {
    getDescription: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const {getDescription} = getDescriptionSlice.actions;
export default getDescriptionSlice.reducer;