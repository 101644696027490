import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { getConcertProgramm } from '../../features/api/getConcertProgramm';
import { Editor } from '@tinymce/tinymce-react';

export default function AddConcertProgramm({ addConcertProgramm }) {
  const [concertProgramm, setConcertProgramm] = React.useState("");
  const [editior, setEditior] = React.useState(false);
  const [isContent, setNewContent] = React.useState("");
  const editorRef = React.useRef(null);
  const dispatch = useDispatch();


  const handleSend = () => {
    if (isContent.trim() === "") return;
    setConcertProgramm(editorRef.current.getContent());
    dispatch(getConcertProgramm(editorRef.current.getContent()));
    setEditior(false);
  };

  const handleClick = (e) => {
    setEditior(e.currentTarget);
  };

  React.useEffect(() => {
    setEditior(true);
    dispatch(getConcertProgramm(addConcertProgramm));
  }, [addConcertProgramm])

  return (
    <div className="add__concert__programm">
      <Box
        sx={{ '& .MuiTextField-root': { m: 1, width: '25ch', bgcolor: "white", }, }}
        noValidate
        autoComplete="off"
      >
        {/* Концертная программа */}
        <Typography
          variant="a"
          sx={{
            mr: 2,
            color: "black",
            fontWeight: 700,
            fontSize: 20,
            display: "flex",
            justifyContent: "center",
            letterSpacing: '.1rem',
            textDecoration: 'none',
            marginBottom: 2,
          }}
        >
          Концертная программа
        </Typography>

        <div style={{ display: "flex", alignItems: "center" }}>
          <Editor
            onEditorChange={(newContent) => setNewContent(newContent)}
            onClick={handleClick}
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={addConcertProgramm}
            init={{
              height: 500,
              width: 700,
              menubar: true,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help'
              ,
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size: 14px }'
            }}
          />
        </div>

        <div className="btn__block" style={{ display: "flex", justifyContent: "end" }}>
          {editior ?
            <button onClick={handleSend} className="btn__description_uncomplete" variant="outlined">Сохранить</button>
            :
            <button onClick={handleSend} className="btn__description_complete" variant="outlined">Сохранено</button>
          }
        </div>
      </Box>
    </div>
  )
}
