import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  quantity: '',
};

export const ticketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    setQuantity: (state, action) => {
      state.quantity = action.payload;
    },
  },
});

export const { setQuantity } = ticketSlice.actions;
export default ticketSlice.reducer;
