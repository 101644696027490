import React, { useState } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import { Menu, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCity } from '../../../features/api/cityApi';
import { getCityId } from '../../../features/api/getCityId';
import axios from 'axios';
import { useEffect } from 'react';

export default function UpdateCity({ cityId, cityIdMain }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tempId, setTempId] = React.useState('');  
  const [isCity, setCity] = React.useState('');
  const allCities = useSelector((state) => state.cityApi.value);
  const dispatch = useDispatch();
  const urlApi = process.env.REACT_APP_API_URL;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getSelectedCityName = () => {
    const selectedCity = allCities.find((city) => city._id === tempId);
    return selectedCity ? selectedCity.name : '';
  };


  const fetchCities = async () => {
    try {
      const response = await axios.get(`${urlApi}/api/getCity/`);
      dispatch(getAllCity(response.data))
    } catch (error) {
      console.log("Ошибка получения данных: ", error);
    };
  };

  React.useEffect(() => {
    setCity(cityId);
    dispatch(getCityId(cityIdMain));
  }, [cityId]);


  return (
    <div className="add__city">
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch', bgcolor: "white" },
        }}
        noValidate
        autoComplete="off"
      >
        {/* Город */}
        <div style={{
          display: "flex",
          alignItems: "center"
        }}
        >
          <TextField
            id="outlined-select-currency-native"
            value={tempId ? getSelectedCityName() : cityId}
            disabled
            label="Город"
          />

          {/* Button */}
          <Button variant="outlined" onClick={handleClick}>
            <EditIcon />
          </Button>

          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <TextField
              id="outlined-select-currency-native"
              select
              value={tempId || ''}
              label="Город"
              type='text'
              onChange={(e) => {
                setTempId(e.target.value);
                dispatch(getCityId(e.target.value));
              }}
              onMouseDown={() => fetchCities()}
              sx={{ width: 300 }}
            >
              {allCities.map((cities) => (
                <MenuItem key={cities._id} value={cities._id} onClick={handleClose}>
                  {cities.name}
                </MenuItem>
              ))};
            </TextField>
          </Menu>
        </div>
      </Box>
    </div>
  )
}