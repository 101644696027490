import React, { useState } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import { Menu, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getAllOrganizer, getOrganizerId } from '../../../features/api/organizerApi';
import axios from 'axios';

export default function UpdateOrganizer({ updateOrganizer, updateOrganizerId }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tempId, setTempId] = React.useState('');
  const [test1, setTest1] = React.useState('');
  const allOrganizers = useSelector((state) => state.organizerApi.value);
  const dispatch = useDispatch();
  const urlApi = process.env.REACT_APP_API_URL;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchOrganizers = async () => {
    try {
      const response = await axios.get(`${urlApi}/api/getOrganizer/`);
      dispatch(getAllOrganizer(response.data));
    } catch (error) {
      console.log("Ошибка получения данных: ", error);
    };
  };

  const getSelectedOrganizerName = () => {
    const selectedOrganizer = allOrganizers.find((organizer) => organizer._id === tempId);
    return selectedOrganizer ? selectedOrganizer.name : '';
  };

  React.useEffect(() => {
    setTest1(updateOrganizer);
    dispatch(getOrganizerId(updateOrganizerId));
  }, [updateOrganizer, updateOrganizerId]);

  return (
    <div className="add__organizator">
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch', bgcolor: "white" },
        }}
        noValidate
        autoComplete="off"
      >
        {/* Организатор */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            id="outlined-select-currency-native"
            value={tempId ? getSelectedOrganizerName() : test1}
            label="Организатор"
            disabled
            type='text'
            onChange={(e) => {
              setTempId(e.target.value);
              dispatch(getOrganizerId(e.target.value))
            }}
            onMouseDown={() => { fetchOrganizers() }}
          >
          </TextField>

          {/* Button */}
          <Button variant="outlined" onClick={handleClick}>
            <EditIcon />
          </Button>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <TextField
              id="outlined-select-currency-native"
              select
              value={tempId || ''}
              label="Организатор"
              type='text'
              onChange={(e) => {
                setTempId(e.target.value);
                dispatch(getOrganizerId(e.target.value))
              }}
              onMouseDown={() => { fetchOrganizers() }}
              sx={{ width: 300 }}
            >
              {allOrganizers.map((organizer) => (
                <MenuItem key={organizer._id} value={organizer._id} onClick={handleClose}>
                  {organizer.name}
                </MenuItem>
              ))}
            </TextField>
          </Menu>
        </div>
      </Box>
    </div>
  )
}