import React, { useState } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { Menu, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAge } from '../../features/api/ageApi';
import { idAllAge } from '../../features/api/ageApi';
import axios from 'axios';
import inMemoryJWT from '../../service/inMemoryJWT';

export default function AddAge() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [tempId, setTempId] = React.useState('');
    const ariaLabel = { 'aria-label': 'description' };
    const allAges = useSelector((state) => state.getAge.value);
    const dispatch = useDispatch();
    const urlApi = process.env.REACT_APP_API_URL;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [formData, setFormData] = useState({
        age: "",
    });

    const handleInputChangePost = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const ResourceClient = axios.create({
        baseURL: `${urlApi}`,
      });
    
      ResourceClient.interceptors.request.use(
        (config) => {
          const accessToken = inMemoryJWT.getToken();
          if (accessToken) {
            config.headers["Authorization"] = `Bearer ${accessToken}`;
          };
          return config;
        },
        (error) => {
          Promise.reject(error);
        }
      );

    // post
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await ResourceClient.post(`${urlApi}/api/setRating/`, formData);
        } catch (error) {
            console.log('Error: ', error);
        };
    };

    // get
    const fetchAges = async () => {
        try {
            const response = await axios.get(`${urlApi}/api/getRating/`);
            dispatch(getAllAge(response.data))
        } catch (error) {
            console.log("Ошибка получения данных: ", error);
        };
    };

    return (
        <div className="add__age">
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch', bgcolor: "white" },
                }}
                noValidate
                autoComplete="off"
            >
                {/* Город */}
                <div style={{ display: "flex", alignItems: "center" }}>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        value={tempId || ''}
                        label="Возрастной рейтинг"
                        onChange={(e) => { setTempId(e.target.value); dispatch(idAllAge(e.target.value)) }}
                        onMouseDown={() => fetchAges()}
                    >
                        {allAges.map((age) => (
                            <MenuItem key={age._id} value={age._id}>
                                {age.age}
                            </MenuItem>
                        ))};
                    </TextField>


                    {/* Button */}
                    <Button variant="outlined" onClick={handleClick}>
                        <AddIcon />
                    </Button>

                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <form onSubmit={handleSubmit}>
                            <label>
                                <TextField value={formData.age} name="age" onChange={handleInputChangePost} placeholder="Возрастной рейтинг" inputProps={ariaLabel} />
                            </label>
                            <Button type="submit" variant="text" onClick={handleClose}>Добавить</Button>
                        </form>
                    </Menu>
                </div>
            </Box>
        </div>
    )
}