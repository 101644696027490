import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { getVideoBody, getVideoHead } from '../../features/api/getMinPrice';

export default function AddLinkVideo({ videoHead, videoBody }) {
  const [isVideoHead, setIsVideoHead] = React.useState('');
  const [isVideoBody, setIsVideoBody] = React.useState('');
  const dispatch = useDispatch();

  // const [form, setForm] = React.useState([{
  //   link: "",
  // }]);

  // const handleInputChange = (e, i) => {
  //   const newFormValues = [...form];
  //   newFormValues[i][e.target.name] = e.target.value;
  //   setForm(newFormValues);
  // }

  const handleChangeHeader = (e) => {
    setIsVideoHead(e)
    dispatch(getVideoHead(e));
  };

  const handleChangeBody = (e) => {
    setIsVideoBody(e)
    dispatch(getVideoBody(e));
  };

  React.useEffect(() => {
    setIsVideoHead(videoHead);
    setIsVideoBody(videoBody)
    dispatch(getVideoHead(videoHead));
    dispatch(getVideoBody(videoBody));
  }, [videoHead, videoBody]);


  return (
    <>
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: '100%', marginLeft: 0 },
        }}
        noValidate
        autoComplete="off"
      >
        {/* <TextField
          value={isVideoHead}
          onChange={(e) => {
            handleChangeHeader(e.target.value)
          }}
          id="outlined-basic"
          label="Видео в шапку"
          variant="outlined"
        /> */}
        <div className="btn__block"
          style={{
            display: "flex",
            justifyContent: "end"
          }}
        >
        </div>
      </Box>
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: '100%', marginLeft: 0 },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          value={isVideoBody}
          onChange={(e) => {
            handleChangeBody(e.target.value)
          }}
          id="outlined-basic"
          label="Видео над галереей"
          variant="outlined"
        />
        <div className="btn__block"
          style={{
            display: "flex",
            justifyContent: "end"
          }}
        >
        </div>
      </Box>
    </>
  )
}
