import * as React from 'react';
import Box from '@mui/material/Box';

import FileUpload from '../components/upload/fileupload';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getIdEvent } from '../features/api/getMinPrice';

import { pathPhotoChange, pathBannerChange, pathLogoChange, pathPreviewChange, pathVideoBodyChange, pathVideoHeadChange } from '../features/savePathFile/photo';
import AddName from '../components/AddEvent/AddName';

import AddMinPrice from '../components/AddEvent/AddMinPrice';
import AddMaxPrice from '../components/AddEvent/AddMaxPrice';
import AddDescription from '../components/AddEvent/AddDescription';
import AddConcertProgramm from '../components/AddEvent/AddConcertProgramm';
import Typography from '@mui/material/Typography';
import AddLinkForm from '../components/AddEvent/AddLinkForm';
// import AddPrew from '../components/AddEvent/AddPrew';

import AddUrlLink from '../components/AddEvent/AddUrl';
import AddTitleSeo from '../components/AddEvent/AddTitleSeo';
import AddDescriptionSeo from '../components/AddEvent/AddDescriptionSeo';
import AddColorButton from '../components/AddEvent/AddColorButton';
import AddLinkVideo from '../components/AddEvent/AddLinkVideo';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import UpdateExecutor from '../components/AddEvent/UpdateEvent/UpdateExecutor';
import UpdateOrganizer from '../components/AddEvent/UpdateEvent/UpdateOrganizer';
import UpdateCity from '../components/AddEvent/UpdateEvent/UpdateCity';
import UpdateLocation from '../components/AddEvent/UpdateEvent/UpdateLocation';
import UpdateTypeEvent from '../components/AddEvent/UpdateEvent/UpdateTypeEvent';
import UpdateAge from '../components/AddEvent/UpdateEvent/UpdateAge';
import UpdateDateTime from '../components/AddEvent/UpdateEvent/UpdateTime';
import axios from 'axios';
import "../scss/Admin.css"
import inMemoryJWT from '../service/inMemoryJWT';

export default function UpdateEvent() {
  const { UpdateEvent } = useParams();
  const [isEvent, setEvent] = React.useState(null);
  const [value, setValue] = React.useState('one');
  const [isSend, setIsSend] = React.useState(false);

  const eventId = useSelector((state) => state.getMinPrice.idEvent)
  const ExecutorId = useSelector((state) => state.executorApi.idExecutor);
  const OrganizatorId = useSelector((state) => state.organizerApi.idOrganizer);
  const LocationId = useSelector((state) => state.locationApi.idLocation);
  const EventId = useSelector((state) => state.getTypeEvent.idEvent);
  const AgeId = useSelector((state) => state.getAge.idAge);
  const date = useSelector((state) => state.getDate.value);
  const time = useSelector((state) => state.getTime.value);
  const finalTime = useSelector((state) => state.getTime.finalTime);
  const priceMin = useSelector((state) => state.getMinPrice.value);
  const priceMax = useSelector((state) => state.getMaxPrice.value);
  const name = useSelector((state) => state.getName.value);
  const description = useSelector((state) => state.getDescription.value);
  const concertProgramm = useSelector((state) => state.getConcertProgramm.value); //<--------
  const gallaryPhoto = useSelector((state) => state.checkEvent.pathGallary);
  const prewPhoto = useSelector((state) => state.checkEvent.pathPreview);
  const logo = useSelector((state) => state.checkEvent.pathLogo);
  const banner = useSelector((state) => state.checkEvent.pathBanner); //<-------- || videoHead
  const urlEvent = useSelector((state) => state.getMinPrice.link);
  const videoHead = useSelector((state) => state.getMinPrice.videoHead); //<---------- || banner
  const videoBody = useSelector((state) => state.getMinPrice.videoBody);
  const urlPage = useSelector((state) => state.getMinPrice.urlLink);
  const titeLink = useSelector((state) => state.seoLinks.seo);
  const colorBkg = useSelector((state) => state.colorButton.colorBkg);
  const colorColor = useSelector((state) => state.colorButton.colorColor);

  const urlApi = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();

  const ResourceClient = axios.create({
    baseURL: `${urlApi}`,
  });

  ResourceClient.interceptors.request.use(
    (config) => {
      const accessToken = inMemoryJWT.getToken();
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );


  const handleSubmint = async () => {
    const formData = new FormData();
    formData.append('_id', eventId);
    formData.append('artist', ExecutorId);
    formData.append('organizer', OrganizatorId);
    formData.append('location', LocationId);
    formData.append('rating', AgeId);
    formData.append('typeEvent', EventId);
    formData.append('name', name);
    formData.append('date', date);
    formData.append('time', time);
    formData.append('duration', finalTime);
    formData.append('minPrice', priceMin);
    formData.append('maxPrice', priceMax);
    formData.append('description', description);
    formData.append('concertProgramm', concertProgramm);
    formData.append('gallaryPhoto', JSON.stringify(gallaryPhoto));
    formData.append('prewPhoto', prewPhoto);
    formData.append('logo', logo);
    formData.append('banner', banner);
    formData.append('urlEvent', urlEvent);
    formData.append('urlVideoHead', videoHead);
    formData.append('urlVideoBody', videoBody);
    formData.append('urlLending', urlPage);
    formData.append('seo', JSON.stringify(titeLink));
    formData.append('backgroundColorButton', colorBkg);
    formData.append('colorButton', colorColor);

    await ResourceClient.post(`${urlApi}/api/updateEvent/`, formData).then(data => {
      console.log(data)
      if (data) {
        setIsSend(true)
      }
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateLogo = (myPath) => {
    dispatch(pathLogoChange(myPath));
  };

  const updateLogoPorps = (myPath) => {
    dispatch(pathLogoChange(myPath));
  };

  const updateBanner = (myPath) => {
    dispatch(pathBannerChange(myPath));
  };

  const updateVideoHead = (myPath) => {
    dispatch(pathVideoHeadChange(myPath));
  };

  const updatePreview = (myPath) => {
    dispatch(pathPreviewChange(myPath));
  };

  const updateVideoBody = (myPath) => {
    dispatch(pathVideoBodyChange(myPath));
  };

  const updateGalary = (myPath) => {
    dispatch(pathPhotoChange(myPath));
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${urlApi}/api/getEvent/${UpdateEvent}`);

      setEvent(response.data);  
      dispatch(getIdEvent(response.data._id))
    } catch (e) {
      console.log(e);
    };
  };


  React.useState(() => {
    if (!isEvent) {
      fetchData();
    };
    return;
  }, [UpdateEvent])


  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  const optionsTime = {
    hour: 'numeric',
    minute: 'numeric',
  };

  return (
    <>
      <Box sx={{ width: '100%', marginTop: 1, marginBottom: 1, marginLeft: 1.3 }}>
        <Stack spacing={2} direction="row">
          <Button variant="outlined" component={Link} to="/Admin">Добавить событие</Button>
          <Button variant="contained" component={Link} to="/Update">Редактировать события</Button>
        </Stack>
      </Box>
      {/* {UpdateEvent} */}
      {/* {setIdEvent(isEvent._id)} */}
      
      {isEvent && (
        <div className="admin__panels" onClick={() => { setIsSend(false) }}>
          <div className="add__other__event" style={{ backgroundColor: "#ffffff", borderRadius: 10, paddingRight: 10, marginTop: 10 }}>
            <UpdateExecutor updateExecutor={isEvent.artist.name} updateExecutorId={isEvent.artist._id} />
            <UpdateOrganizer updateOrganizer={isEvent.organizer.name} updateOrganizerId={isEvent.organizer._id} />
            <UpdateCity cityId={isEvent.location.city.name} cityIdMain={isEvent.location.city._id} />
            <UpdateLocation locationId={isEvent.location.place} locationIdMain={isEvent.location._id} />
            <UpdateTypeEvent typeEvent={isEvent.typeEvent} typeEventId={isEvent.typeEvent._id} />
            <UpdateAge updateAge={isEvent.rating.age} updateAgeId={isEvent.rating._id} />
            <UpdateDateTime
              dateDate={new Date(isEvent.dateTime).toLocaleString("ru", options)}
              dateTime={new Date(isEvent.dateTime).toLocaleString("ru", optionsTime)}
              dateLastTime={isEvent.duration}
            />
            <AddMinPrice valueMinPrice={isEvent.minPrice} />
            <AddMaxPrice valueMaxPrice={isEvent.maxPrice} />
            <AddLinkForm valueLinkTable={isEvent.urlEvent} />
          </div>

          <div className="name__of__event" style={{ backgroundColor: "#ffffff", marginTop: 10 }}>
            <div className="add__text">
              <div className="add__name__event" style={{ display: "flex", justifyContent: "center", borderRadius: 40 }}>
                <AddName addName={isEvent.name} />
              </div>
              <div className="add__description__event" style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
                <AddDescription addDescription={isEvent.description} />
              </div>
              <div className="add__concert__programm__event" style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
                <AddConcertProgramm addConcertProgramm={isEvent.concertProgramm} />
              </div>
            </div>

            <div className="add__upload" style={{ color: "#000000", marginBottom: -15 }}>
              <div className="add__url__link">
                <AddUrlLink addUrl={isEvent.urlLending} />
              </div>

              <div className="add__color__button">
                <AddColorButton colorBkg={isEvent.backgroundColorButton} colorTxt={isEvent.colorButton} />
              </div>

              {/* <hr className="border__line__upload" /> */}

              <div className="add__link__video">
                <AddLinkVideo
                  videoHead={isEvent.videoHead  ? JSON.parse(isEvent.videoHead)[0].url : ''}
                  videoBody={isEvent.videoBody  ? JSON.parse(isEvent.videoBody)[0].url : ''}
                />
              </div>

              {/* {console.log(JSON.parse(isEvent.videoBody)[0].ownerId)} */}

              <hr className="border__line__upload" />

              {/* <div className="upload__file">
                <Typography className="title__upload">Изображение названия мероприятия</Typography>
                <FileUpload pathToSave={'logo'} onFileChange={updateLogo} setPhotos={[isEvent.logo]} setImg={[isEvent.logo]} />
              </div>

              <hr className="border__line__upload" />

              <div className="upload__file">
                <Typography className="title__upload">Изображение в шапку</Typography>
                <FileUpload pathToSave={'banner'} onFileChange={updateBanner} setPhotos={[isEvent.banner]} />
              </div>

              <hr className="border__line__upload" />

              <div className="upload__file">
                <Typography className="title__upload">Привью для главной страницы</Typography>
                <FileUpload pathToSave={'preview'} onFileChange={updatePreview} setPhotos={[isEvent.previewPhoto]} />
              </div>

              <hr className="border__line__upload" />

              <div className="upload__file">
                <Typography className="title__upload">Галерея кратное трём</Typography>
                <FileUpload pathToSave={'gallary'} onFileChange={updateGalary} setPhotos={isEvent.gallaryPhoto} />
              </div> */}

            </div>
          </div>

          <div className="add__seo__event" style={{ backgroundColor: "#ffffff", borderRadius: 10, marginTop: 10, }}>
            <AddTitleSeo seoTitle={JSON.parse(isEvent.seo).title} />
            <AddDescriptionSeo seoDescription={JSON.parse(isEvent.seo).description} />
          </div>
          {!isSend ?
            <button
              className="btn__send__admin__form"
              onClick={handleSubmint}
            >
              Обновить ивент
            </button>
            :
            <button
              className="btn__sended__admin__form"
              onClick={handleSubmint}
            >
              Готово
            </button>
          }
        </div>
      )}
    </>
  )
}
