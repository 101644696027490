import React, { useEffect, useState } from 'react';
import styles from './Header.module.scss';

import BiletovLogo from '../../assets/biletov.png';

import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';

import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useSelector, useDispatch } from "react-redux";
import { incrementByAmount } from "../../features/city/cityChoicer";
import { Helmet } from 'react-helmet';
import axios from 'axios';

// компонент шапки сайта
const Header = () => {
  const count = useSelector((state) => state.city.value);
  const [isCities, setIsCities] = useState([]);
  const dispatch = useDispatch();
  const urlApi = process.env.REACT_APP_API_URL;

  const fetchCities = async () => {
    try {
      const response = await axios.get(`${urlApi}/api/getCity/`);
      setIsCities(response.data);
    } catch (e) {
      console.log(e);
    };
  };

  useEffect(() => {
    fetchCities()
  }, [])

  return (
    <>
      <Helmet>

        <script type="text/javascript">{`
      (function ab() {
        var request = new XMLHttpRequest();
        request.open("GET", "https://scripts.botfaqtor.ru/one/117303", false);
        request.send();
        if (request.status == 200) eval(request.responseText);
      })();
      `}
        </script>

        {/* <!-- Yandex.Metrika counter --> */}
        <script type="text/javascript">{`
      (function (m, e, t, r, i, k, a) {
        m[i] =
          m[i] ||
          function () {
            (m[i].a = m[i].a || []).push(arguments);
          };
        m[i].l = 1 * new Date();
        for (var j = 0; j < document.scripts.length; j++) {
          if (document.scripts[j].src === r) {
            return;
          }
        }
        (k = e.createElement(t)),
          (a = e.getElementsByTagName(t)[0]),
          (k.async = 1),
          (k.src = r),
          a.parentNode.insertBefore(k, a);
      })(
        window,
        document,
        "script",
        "https://mc.yandex.ru/metrika/tag.js",
        "ym"
      );

      ym(95657055, "init", {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      });
      `}
        </script>
        <noscript>{`
      <div>
        <img
          src="https://mc.yandex.ru/watch/95657055"
          style="position: absolute; left: -9999px"
          alt=""
        />
      </div>
      `}
        </noscript>
        {/* <!-- /Yandex.Metrika counter --> */}

        {/* <!-- Top.Mail.Ru counter --> */}
        <script type="text/javascript">{`
      var _tmr = window._tmr || (window._tmr = []);
      _tmr.push({
        id: "3467367",
        type: "pageView",
        start: new Date().getTime(),
      });
      (function (d, w, id) {
        if (d.getElementById(id)) return;
        var ts = d.createElement("script");
        ts.type = "text/javascript";
        ts.async = true;
        ts.id = id;
        ts.src = "https://top-fwz1.mail.ru/js/code.js";
        var f = function () {
          var s = d.getElementsByTagName("script")[0];
          s.parentNode.insertBefore(ts, s);
        };
        if (w.opera == "[object Opera]") {
          d.addEventListener("DOMContentLoaded", f, false);
        } else {
          f();
        }
      })(document, window, "tmr-code");
      `}
        </script>
        <noscript>{`
      <div>
        <img
          src="https://top-fwz1.mail.ru/counter?id=3467367;js=na"
          style="position: absolute; left: -9999px"
          alt="Top.Mail.Ru"
        />
        </div>
        `}
        </noscript>
        {/* <!-- /Top.Mail.Ru counter --> */}
        <script>{`
         (function(w,d,u){
            var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/60000|0);
            var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
         })(window,document,'https://cdn-ru.bitrix24.ru/b2972213/crm/site_button/loader_6_vbnjx0.js');`}
        </script>
      </Helmet>
      <div className={styles.page}>
        <header className={styles.header}>
          <div className={styles.header__leftblock}>
            <Link to="/">
              <img className={styles.header__leftblock__img} src={BiletovLogo} alt="Билетов.рус" />
            </Link>
          </div>
          <div className={styles.header__rightblock}>
            <Select
              sx={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
              className={styles.header__rightblock__select}
              value={count}
              variant="solid"
              color='neutral'
            >
              <Link to="/" className={styles.header__rightblock__select__linkselect}>
                <Option value="Курск" onClick={() => dispatch(incrementByAmount("Курск"))}>Курск</Option>
              </Link>
              <Link to="/Oscol" className={styles.header__rightblock__select__linkselect}>
                <Option value="Старый Оскол" onClick={() => dispatch(incrementByAmount("Старый Оскол"))}>Старый Оскол</Option>
              </Link>

              {/* {
                isCities.filter((element) => {
                  return element.name !== "Курск";
                }).map((element, index) => (
                  <Link key={index} to={`/${element.translit}`} className={styles.header__rightblock__select__linkselect}>
                    <Option value={element.name} onClick={() => dispatch(incrementByAmount(element.name))}>{element.name}</Option>
                  </Link>
                ))
              } */}
            </Select>
          </div>
        </header>
      </div>
    </>
  );
};

export default Header;
