import { createSlice } from '@reduxjs/toolkit';

export const getMinPriceSlice = createSlice({
  name: 'minPrice',
  initialState: {
    value: "",
    link: "",
    urlLink: "",
    videoHead: "",
    videoBody: "",
    idEvent: "",
  },
  reducers: {
    getMinPrice: (state, action) => {
      state.value = action.payload;
    },
    getLink: (state, action) => {
      state.link = action.payload;
    },
    getUrl: (state, action) => {
      state.urlLink = action.payload;
    },
    getVideoHead: (state, action) => {
      state.videoHead = action.payload
    },
    getVideoBody: (state, action) => {
      state.videoBody = action.payload
    },
    getIdEvent: (state, action) => {
      state.idEvent = action.payload
    },
  },
});

export const { getMinPrice } = getMinPriceSlice.actions;
export const { getLink } = getMinPriceSlice.actions;
export const { getUrl } = getMinPriceSlice.actions;
export const { getVideoHead } = getMinPriceSlice.actions;
export const { getVideoBody } = getMinPriceSlice.actions;
export const { getIdEvent } = getMinPriceSlice.actions;
export default getMinPriceSlice.reducer;