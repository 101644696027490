import { createSlice } from '@reduxjs/toolkit';

export const getCitySlice = createSlice({
  name: 'cityApi',
  initialState: {
    value: [],
  },
  reducers: {
    getAllCity: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const {getAllCity} = getCitySlice.actions;
export default getCitySlice.reducer;