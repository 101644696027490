import React, { useEffect } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Editor } from '@tinymce/tinymce-react';
import { useDispatch } from 'react-redux';
import { getName } from '../../features/api/getName';
import { clearNameMain } from '../../features/api/getName';

export default function AddName({ addName }) {
  const [name, setName] = React.useState("");
  const [editior, setEditior] = React.useState(false);
  const [isContent, setNewContent] = React.useState("");
  const editorRef = React.useRef(null);
  const dispatch = useDispatch();

  const handleSend = (e) => {
    if (isContent.trim() === "") return;
    let text = new DOMParser().parseFromString(editorRef.current.getContent(), 'text/html').body.textContent.trim();
    setName(editorRef.current.getContent());
    dispatch(getName(editorRef.current.getContent()));
    dispatch(clearNameMain(text));
    setEditior(false);
  };

  const handleClick = (e) => {
    setEditior(e.currentTarget);
  };

  useEffect(() => {
    setEditior(true);
    dispatch(getName(addName));
  }, [addName]);

  return (
    <div className="add__name__event">
      <Box
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch', bgcolor: "white" },
        }}
        noValidate
        autoComplete="off"
      >
        <Typography
          variant="a"
          sx={{
            mr: 2,
            color: "black",
            fontWeight: 700,
            fontSize: 20,
            display: "flex",
            justifyContent: "center",
            letterSpacing: '.1rem',
            textDecoration: 'none',
            marginBottom: 2,
          }}
        >
          Название мероприятия
        </Typography>
        {/* Название */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <Editor
            initialValue={addName}
            onEditorChange={(newContent) => setNewContent(newContent)}
            onClick={handleClick}
            onInit={(evt, editor) => editorRef.current = editor}
            init={{
              height: 190,
              width: 700,
              menubar: true,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help'
              ,
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size: 14px }'
            }}
          />
        </div>

        <div className="btn__block" style={{ display: "flex", justifyContent: "end" }}>
          {editior ?
            <button
              onClick={handleSend}
              className="btn__description_uncomplete"
              variant="outlined"
            >
              Сохранить
            </button>
            :
            <button
              onClick={handleSend}
              className="btn__description_complete"
              variant="outlined"
            >
              Сохранено
            </button>
          }
        </div>
      </Box>
    </div>
  );
}