import { createSlice } from '@reduxjs/toolkit';

export const getMaxPriceSlice = createSlice({
  name: 'maxPrice',
  initialState: {
    value: "",
  },
  reducers: {
    getMaxPrice: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const {getMaxPrice} = getMaxPriceSlice.actions;
export default getMaxPriceSlice.reducer;