import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Header from '../components/Header/Header';
import { useDispatch } from "react-redux";
import { incrementByAmount } from "../features/city/cityChoicer";
import { Helmet } from 'react-helmet';

export default function Error() {
  const dispatch = useDispatch();
  dispatch(incrementByAmount('Курск'));

  return (
    <>
      <Helmet>
        <title>404 Not Found</title>
      </Helmet>
      <Header />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: 30,
          marginBottom: 30
        }}
      >
        <Typography variant="h1" style={{ color: 'white', textAlign: "center", }}>
          404
        </Typography>
        <Typography variant="h6" style={{ color: 'white', textAlign: "center" }}>
          Страница несуществует или была перемещена... 😞
        </Typography>

        <Button style={{ marginTop: 40 }} component={Link} to="/" variant="contained">Вернуться на главную</Button>
      </Box>
    </>
  );
}