import { createSlice } from '@reduxjs/toolkit';

export const getOrganizerSlice = createSlice({
  name: 'organizerApi',
  initialState: {
    value: [],
    idOrganizer: "",
  },
  reducers: {
    getAllOrganizer: (state, action) => {
      state.value = action.payload;
    },
    getOrganizerId: (state, action) => {
      state.idOrganizer = action.payload;
    },
  },
});

export const {getAllOrganizer} = getOrganizerSlice.actions;
export const {getOrganizerId} = getOrganizerSlice.actions;
export default getOrganizerSlice.reducer;