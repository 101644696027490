import { createSlice } from '@reduxjs/toolkit';

export const getExecutorSlice = createSlice({
  name: 'executorApi',
  initialState: {
    value: [],
    idExecutor: "",
  },
  reducers: {
    getAllExecutor: (state, action) => {
      state.value = action.payload;
    },
    idAllExecutor: (state, action) => {
      state.idExecutor = action.payload
    }
  },
});

export const {getAllExecutor} = getExecutorSlice.actions;
export const {idAllExecutor} = getExecutorSlice.actions;
export default getExecutorSlice.reducer;