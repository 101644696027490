import { createSlice } from '@reduxjs/toolkit';

export const citySlice = createSlice({
  name: 'city',
  initialState: {
    value: "Выберите город",
  },
  reducers: {
    incrementByAmount: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const {incrementByAmount} = citySlice.actions;
export default citySlice.reducer;