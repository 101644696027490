import { createSlice } from '@reduxjs/toolkit';

export const eventSlice = createSlice({
  name: 'eventCard',
  initialState: {
    eventCard: [],
  },
  reducers: {
    eventCardMain: (state, action) => {
      state.eventCard = action.payload;
    },
  },
});

export const { eventCardMain } = eventSlice.actions;
export default eventSlice.reducer;